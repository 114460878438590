import React, {
  useState,
  useEffect,
  useCallback,
  Fragment,
  useContext,
  useMemo,
} from "react";
import { Link, Redirect } from "react-router-dom";
import AuthService, {
  UnauthorizedError,
  ValidationError,
  UnregisteredError,
  InvalidUsernamePasswordError,
} from "../../services/auth.service";

import { days } from "../../days.json";

import { getDefaultAuthedRoute, ROUTE_FORGOT_PASSWORD } from "../../App";
import { AppContext } from "../../contexts/AppContext";

import Page from "../../components/Page";
import LeftPanel from "../../components/LeftPanel";
import RightPanel from "../../components/RightPanel";
import Input from "../../components/Input";
import ErrorMessage from "../../components/ErrorMessage";
import ButtonWithPrompt from "../../components/ButtonWithPrompt";

import style from "./index.module.scss";

const STEP_EMAIL = "email";
const STEP_REGISTER = "register";
const STEP_PASSWORD = "password";

const GENERIC_ERROR = "Something went wrong. Please try again.";
const EMAIL_NOT_AUTHORIZED_ERROR =
  "This email is not registered to attend the event.";

const DAY_ONE_CONTENT = days[0].welcome_screen;

const Auth = () => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(STEP_EMAIL);
  const [userRegistered, setUserRegistered] = useState(false);

  const [globalError, setGlobalError] = useState(null);
  const [globalErrorContact, setGlobalErrorContact] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");

  const { updateMetadata, updateUserProgress } = useContext(AppContext);

  // Reset the form error when any input is typed in
  useEffect(() => {
    setGlobalError(null);
  }, [password, email]);

  const handleError = useCallback((error, field) => {
    setFieldErrors((oldFieldErrors) => ({
      ...oldFieldErrors,
      [field]: error,
    }));
  }, []);

  const handleReset = useCallback(() => {
    // First and Last name can be kept as they're going to be the same if a user
    // gets to register an account for a second time.
    setEmail("");
    setPassword("");
    setUserRegistered(false);
    setStep(STEP_EMAIL);
  }, []);

  const handleEmailSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (fieldErrors.email) return;

      setLoading(true);
      setGlobalError(null);
      setGlobalErrorContact(false);

      let response;

      try {
        response = await AuthService.getUserStatus(email);
      } catch (e) {
        let newGlobalError = GENERIC_ERROR;

        if (e instanceof ValidationError) {
          newGlobalError = "Please enter a valid email address";
        }

        setGlobalError(newGlobalError);
        setLoading(false);
        return;
      }

      // Short timeout gives the user a feeling of something happening
      setTimeout(() => {
        if (!response.is_registered_for_current_workshop) {
          setGlobalErrorContact(true);
          setGlobalError(EMAIL_NOT_AUTHORIZED_ERROR);
        } else if (response.is_password_set) {
          setUserRegistered(true);
          setStep(STEP_PASSWORD);
        } else {
          setStep(STEP_REGISTER);
        }

        setLoading(false);
      }, 500);
    },
    [email, fieldErrors.email]
  );

  const handleRegisterSubmit = useCallback((e) => {
    e.preventDefault();
    setLoading(true);

    setTimeout(() => {
      setStep(STEP_PASSWORD);

      setLoading(false);
    }, 500);
  }, []);

  const handlePasswordSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (fieldErrors.password) return null;

      setLoading(true);
      setGlobalError(null);
      setGlobalErrorContact(false);

      try {
        if (userRegistered) {
          await AuthService.login({ email, password });
        } else {
          AuthService.setLastLocation(null);
          await AuthService.register({ email, password, firstName, lastName });
        }
      } catch (e) {
        let newGlobalError;
        let newGlobalErrorContact = true;

        if (e instanceof UnauthorizedError) {
          newGlobalError = EMAIL_NOT_AUTHORIZED_ERROR;
        } else if (e instanceof UnregisteredError) {
          newGlobalError = "You are not registered for the current event.";
        } else if (e instanceof InvalidUsernamePasswordError) {
          // This is safe to show to a user because even though the API error doesn't explicityly
          // confirm that a user exists, they are only able to get to this part of the flow if
          // they have an account already. There is no security concern with showing this error message.
          newGlobalError = "Incorrect password.";
        } else {
          newGlobalError = GENERIC_ERROR;
          newGlobalErrorContact = false;
        }

        setTimeout(() => {
          setGlobalError(newGlobalError);
          setGlobalErrorContact(newGlobalErrorContact);
          setLoading(false);
        }, 500);

        return;
      }

      await updateMetadata();
      updateUserProgress();
    },
    [
      fieldErrors.password,
      email,
      password,
      firstName,
      lastName,
      updateMetadata,
      updateUserProgress,
      userRegistered,
    ]
  );

  const globalErrorWithContact = useMemo(
    () => (
      <Fragment>
        <strong>
          {globalError} Ensure you have typed the correct details.{" "}
        </strong>

        {globalErrorContact && (
          <Fragment>
            <button onClick={handleReset} type="button">
              Try a different email
            </button>{" "}
            and{" "}
            <a
              href="https://www.tonyrobbins.com/contact-us/"
              target="_blank"
              rel="noreferrer"
            >
              contact Tony&nbsp;Robbins customer service
            </a>{" "}
            if this issue&nbsp;persists.
          </Fragment>
        )}
      </Fragment>
    ),
    [globalError, handleReset, globalErrorContact]
  );

  if (AuthService.isAuthenticated()) {
    return <Redirect to={getDefaultAuthedRoute()} />;
  }

  return (
    <Page className={style.Auth} day={1}>
      <LeftPanel
        title={
          <Fragment>
            <strong>{DAY_ONE_CONTENT.title}</strong> <br />
            {DAY_ONE_CONTENT.sub_title}
          </Fragment>
        }
        description={
          <Fragment>
            {DAY_ONE_CONTENT.description.map((copy, index) => (
              <p key={index}>{copy}</p>
            ))}
          </Fragment>
        }
        quote={DAY_ONE_CONTENT.quote}
      />
      <RightPanel single>
        {step === STEP_EMAIL && (
          <form onSubmit={handleEmailSubmit} key={STEP_EMAIL} noValidate>
            <Input
              id="email"
              fieldName="Email"
              label="What is your email address?"
              type="email"
              name="email"
              placeholder="My email address is…"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onError={handleError}
              error={fieldErrors.email}
              required
              autoFocus
              autoComplete="email"
              readonly={loading}
            />

            {email && (
              <ButtonWithPrompt
                amendPrompt={false}
                disabled={fieldErrors.email}
                loading={loading}
              />
            )}
            {globalError && (
              <ErrorMessage
                className={style.AuthFormError}
                error={globalErrorWithContact}
              />
            )}
          </form>
        )}

        {step === STEP_REGISTER && (
          <form onSubmit={handleRegisterSubmit} key={STEP_REGISTER} noValidate>
            <div className={style.AuthFormName}>
              <Input
                className={style.AuthFormNameInput}
                id="first_name"
                fieldName="First name"
                label="What is your name?"
                aria-label="What is your first name?"
                type="text"
                name="first_name"
                placeholder="First name…"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                autoFocus
                autoComplete="given-name"
                readonly={loading}
              />

              <Input
                className={style.AuthFormNameInput}
                id="last_name"
                fieldName="Last name"
                label="What is your last name?"
                aria-label="What is your last name?"
                type="text"
                name="last_name"
                placeholder="Last name…"
                autoComplete="family-name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                readonly={loading}
              />
            </div>

            {firstName && lastName && (
              <ButtonWithPrompt amendPrompt={false} loading={loading} />
            )}
          </form>
        )}

        {step === STEP_PASSWORD && (
          <form onSubmit={handlePasswordSubmit} key={STEP_PASSWORD} noValidate>
            <Input
              id="password"
              fieldName="Password"
              label={
                userRegistered ? "What is your password?" : "Set a password…"
              }
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={fieldErrors.password}
              onError={handleError}
              placeholder={
                userRegistered
                  ? "Enter your password…"
                  : "Enter a new password…"
              }
              required
              min={8}
              autoFocus
              autoComplete={userRegistered ? "new-password" : "password"}
              readonly={loading}
              helper={
                userRegistered ? (
                  <Link to={ROUTE_FORGOT_PASSWORD}>Forgot password?</Link>
                ) : undefined
              }
            />

            {password && (
              <ButtonWithPrompt
                amendPrompt={false}
                label={userRegistered ? "Log in" : "Complete Registration"}
                loading={loading}
                disabled={fieldErrors.password}
              />
            )}
            {globalError && (
              <ErrorMessage
                className={style.AuthFormError}
                error={globalErrorWithContact}
              />
            )}
          </form>
        )}
      </RightPanel>
    </Page>
  );
};

export default Auth;
