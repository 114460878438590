import axios from "axios";
import { API_URL, API_VERSION } from "../config";

import { LOCAL_AUTH_TOKEN } from "./auth.service.js";

export default function createApiClient() {
  const token = localStorage.getItem(LOCAL_AUTH_TOKEN);

  return axios.create({
    baseURL: API_URL,
    headers: {
      Accept: `application/json; version=${API_VERSION}`,
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
}
