import React from "react";
import cc from "classcat";

import style from "./index.module.scss";

const Checkbox = ({ className, label, checked, onChange }) => (
  <label className={cc([className, style.Checkbox])}>
    <input
      className="sr-only"
      type="checkbox"
      checked={checked}
      onChange={onChange}
    />
    <span className={style.CheckboxBox} aria-hidden="true" />
    <span className={style.CheckboxText}>{!!label && label}</span>
  </label>
);

export default Checkbox;
