import api from "./api.service";

/**
 * Get app metadata
 */
async function getMetadata() {
  let response;

  try {
    response = await api().get("/metadata");
  } catch ({ response }) {
    const [error] = response?.data?.errors || [];

    throw new Error(error?.message);
  }

  return response.data;
}

const AppService = {
  getMetadata,
};

export default AppService;
