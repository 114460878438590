import api from "./api.service";

import AuthService from "./auth.service";

/**
 * Sends answers based on the schema in https://fueled.github.io/rri-dwd-backend/api/4-answers/
 */
async function setAnswer(answer, dayId, dayProgress) {
  let response;

  const oldUser = AuthService.getUser();

  if (
    JSON.stringify(oldUser?.answer) ===
    JSON.stringify({ ...oldUser?.answer, ...answer })
  ) {
    // Nothing changed, skipping the backend call to save resources
    return oldUser?.answer;
  }

  const oldProgressDetails = oldUser?.answer?.progress_details || {};

  const progressDetails = {
    ...oldProgressDetails,
    // This ensures that we don't overwrite the progress when a user returns to an earlier
    // part of the flow and resubmits
    [dayId]: Math.max(dayProgress, oldProgressDetails[dayId] || 0),
  };

  try {
    response = await api().post("/answers", {
      ...answer,
      progress_details: progressDetails,
    });
  } catch (e) {
    const [error] = e?.response?.data?.errors || [];

    throw new Error(error?.message);
  }

  const answers = response.data;

  const newUser = AuthService.getUser();
  newUser.answer = answers;

  AuthService.updateUser(newUser);

  return answers;
}

const QuizService = {
  setAnswer,
};

export default QuizService;
