import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useContext,
} from "react";
import {
  useHistory,
  useLocation,
  generatePath,
  Redirect,
} from "react-router-dom";
import cc from "classcat";

import { fakeLoading } from "../../utils";
import AuthService from "../../services/auth.service";
import { trackEvent } from "../../services/analytics.service";

import { ROUTE_POSTER, ROUTE_DAY_COMPLETED } from "../../App";
import { POSTER_STYLE } from "../Poster/poster.config";
import { AppContext } from "../../contexts/AppContext";

import Button from "../../components/Button";
import Page from "../../components/Page";
import LeftPanel from "../../components/LeftPanel";
import RightPanel from "../../components/RightPanel";
import Toolbar from "../Toolbar";

import { ReactComponent as ProgressCompleted } from "../../images/icons/progress-completed.svg";

import style from "./index.module.scss";

const SESSION_THEME_SELECTION = "selectedTheme";

const THEMES = [
  {
    id: POSTER_STYLE.BLUE,
    title: "Blue",
  },
  {
    id: POSTER_STYLE.SIMPLY_SAGE,
    title: "Simply Sage",
  },
  {
    id: POSTER_STYLE.GREEN,
    title: "Green",
  },
  {
    id: POSTER_STYLE.WOODLAND_WAVE,
    title: "Woodland Wave",
  },
  {
    id: POSTER_STYLE.ORANGE,
    title: "Orange",
  },
  {
    id: POSTER_STYLE.BOLDLY_BRUSHED,
    title: "Boldly Brushed",
  },
  {
    id: POSTER_STYLE.RED,
    title: "Red",
  },
  {
    id: POSTER_STYLE.STRIKING_SURREAL,
    title: "Striking Surreal",
  },
  {
    id: POSTER_STYLE.PURPLE,
    title: "Purple",
  },
  {
    id: POSTER_STYLE.CREATIVITY_CLOUD,
    title: "Creativity Cloud",
  },
];

const PosterWelcome = () => {
  const history = useHistory();
  const location = useLocation();

  const [selectedStyle, setSelectedStyle] = useState(
    sessionStorage.getItem(SESSION_THEME_SELECTION) || THEMES[0].id
  );
  const [loading, setLoading] = useState(false);

  const { currentDay } = useContext(AppContext);

  useEffect(() => {
    AuthService.setLastLocation(location.pathname);
  }, [location.pathname]);

  const handleChange = useCallback((e) => {
    const value = e.target.value;
    setSelectedStyle(value);
    sessionStorage.setItem(SESSION_THEME_SELECTION, value);
  }, []);

  const handleClick = useCallback(async () => {
    setLoading(true);

    // Update the user before generating the poster to be extra sure that all the data
    // is up to date.
    await AuthService.user();
    const posterRoute = generatePath(ROUTE_POSTER, { style: selectedStyle });

    trackEvent("userGeneratedPoster", {
      style: selectedStyle,
    });

    // Small delay for a better UX
    await fakeLoading();

    setLoading(false);
    history.push(posterRoute);
  }, [history, selectedStyle]);

  if (!currentDay) return null;

  if (currentDay < 6) {
    return (
      <Redirect to={generatePath(ROUTE_DAY_COMPLETED, { dayId: currentDay })} />
    );
  }

  return (
    <Page className={style.Home} day="5">
      <LeftPanel
        className={style.HomeSidebar}
        title={
          <Fragment>
            <strong>My Date With Destiny®</strong> <br />
            Virtual Poster
          </Fragment>
        }
        description={
          "Choose the template you’d like to use for your poster. Once you select your template, you will have the opportunity to edit the text for each of the elements on your poster. Then, you can create a download of your poster to share with your Date With Destiny trainers, partner, or team as you integrate and own your new\xa0destiny!"
        }
      />

      <RightPanel className={style.PosterWelcomeRightPanel}>
        <Toolbar />
        <div className={style.PosterWelcomeList}>
          {THEMES.map((theme) => (
            <label
            key={theme.id}
            className={cc([
              style.PosterWelcomeItem,
              style[`PosterWelcomeItem-${theme.id}`],
              {
                [style.PosterWelcomeItemActive]: selectedStyle === theme.id,
              },
            ])}
          >
            <input
              className="sr-only"
              type="radio"
              name="theme"
              label={theme.title}
              value={theme.id}
              onChange={handleChange}
              checked={selectedStyle === theme.id}
            />
            <span className={style.PosterWelcomeItemCheck} aria-hidden="true">
              <ProgressCompleted />
            </span>
          </label>
          ))}
        </div>
        <Button
            className={style.PosterWelcomeCta}
            onClick={handleClick}
            loading={loading}
          >
          Get Started
        </Button>
      </RightPanel>
    </Page>
  );
};

export default PosterWelcome;
