import React from "react";
import cc from "classcat";
import style from "./index.module.scss";

const ProgressCircle = ({
  perc = 0,
  size = 110,
  strokeSize = 11,
  text,
  className,
  ...props
}) => {
  if (typeof perc !== "number") perc = 0;
  if (perc > 100) perc = 100;
  perc = Math.round(perc);

  const diameter = size - strokeSize;
  const circumference = Math.PI * diameter;
  const dash = (circumference * perc) / 100;
  const gap = circumference - dash;

  return (
    <svg
      className={cc([style.Progress, className])}
      width={size}
      height={size}
      {...props}
    >
      <title>{perc}% completed</title>
      <circle cx="50%" cy="50%" r={diameter / 2} strokeWidth={strokeSize} />
      {/* Safari draws a 1px slice of the colored circle even with dash==0...
          This is why I completely remove the object when dash==0 */}
      {dash > 0 && (
        <circle
          cx="50%"
          cy="50%"
          r={diameter / 2}
          strokeWidth={strokeSize}
          strokeDasharray={`${dash} ${gap}`}
        />
      )}
      <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">
        {text}
      </text>
    </svg>
  );
};

export default ProgressCircle;
