import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import { Link, useParams } from "react-router-dom";
import cc from "classcat";
import { format, isThisYear, isToday, isYesterday } from "date-fns";

import { ROUTE_ADMIN } from "../../App";

import {
  getClient,
  flagClient,
  updateClientNotes,
} from "../../services/admin.service";
import { POSTER_STYLE } from "../Poster/poster.config";
import { drawPoster } from "../Poster/poster.helpers";
import { sorter } from "../../utils/common";

import ProgressCircle from "../../components/ProgressCircle";
import AdminNav from "../../components/AdminNav";
import AdminRefreshButton from "../../components/AdminRefreshButton";
import CommentsPanel from "./CommentsPanel";

import { ReactComponent as BackIcon } from "../../images/icons/back.svg";
import { ReactComponent as CommentsIcon } from "../../images/icons/comments.svg";

import style from "./index.module.scss";

const checkMissing = (value) =>
  value || <span className={style.Missing}>Not entered.</span>;

const progress = (text, perc) => (
  <ProgressCircle
    className={style.DayProgress}
    size={110}
    strokeSize={11}
    text={text}
    perc={perc}
  />
);

const AdminClient = () => {
  const { clientId } = useParams();

  const [loading, setLoading] = useState(true);
  const [client, setClient] = useState();

  const notesDebounceTimeout = useRef(null);

  const [commentsVisible, setCommentsVisible] = useState(false);

  const posterPlaceholderRef = useRef(null);
  const [isPosterVisible, setIsPosterVisible] = useState(false);
  const [isPosterLoading, setIsPosterLoading] = useState(false);

  const refresh = useCallback(() => {
    // Show a bit of loading times so it doesn't look broken when it's too fast
    const MINIMUM_DELAY = 1000;
    const startTime = new Date().getTime();
    setLoading(true);
    setIsPosterVisible(false); // poster must rerender in case user data changes
    getClient(clientId).then((client) => {
      const elapsed = new Date().getTime() - startTime;
      const delay = Math.max(0, MINIMUM_DELAY - elapsed);
      setTimeout(() => {
        setClient(client);
        setLoading(false);
      }, delay);
    });
  }, [clientId]);

  // When clientId changes load data from the cache to immediately display
  // something, then in the background fetch updated data from the backend
  useEffect(() => {
    getClient(clientId, true).then((client) => {
      setClient(client);
      refresh();
    });
  }, [clientId, refresh]);

  const handleFlag = useCallback(
    async (e) => {
      const newValue = e.target.checked;
      setClient((client) => ({ ...client, is_client_at_risk: newValue }));

      try {
        await flagClient(clientId, newValue);
      } catch (e) {
        console.error(e);
        alert(
          "Error while saving the flag state for this client.\n" +
            "Check your internet connection and try again."
        );
      }
    },
    [clientId]
  );

  const handleClientNotesChange = useCallback(
    (e) => {
      const newValue = e.currentTarget.value;
      setClient((client) => ({ ...client, trainer_private_notes: newValue }));

      async function save() {
        try {
          await updateClientNotes(clientId, newValue);
          notesDebounceTimeout.current = null;
        } catch (e) {
          console.error(e);
          alert(
            "Error while saving the notes for this client.\n" +
              "Check your internet connection and try again."
          );
        }
      }

      clearTimeout(notesDebounceTimeout.current);
      notesDebounceTimeout.current = setTimeout(save, 500);
    },
    [clientId]
  );

  const handleTogglePoster = useCallback(() => {
    if (isPosterLoading) {
      return;
    }
    setIsPosterVisible(!isPosterVisible);
  }, [isPosterLoading, isPosterVisible]);

  const fullName =
    client && client.first_name && client.last_name
      ? `${client.first_name} ${client.last_name}`
      : "(no name)";

  const data = useMemo(() => {
    const data = client?.answers?.[0] || {};

    const sanitize = (value) => (typeof value === "string" ? value.trim() : "");

    const powerVirtuesRaw = [...(data.power_virtues || [])].sort(sorter);
    const powerVirtues = powerVirtuesRaw.map((obj) => sanitize(obj.name));
    if (powerVirtues.length === 0) powerVirtues.push("");

    const sanitizeValuesAndRules = (arr) => {
      const valuesAndRulesRaw = [...(arr || [])].sort(sorter);
      const valuesAndRules = valuesAndRulesRaw.map((obj) => ({
        value: sanitize(obj.value),
        rule: sanitize(obj.rule),
      }));
      if (valuesAndRules.length === 0)
        valuesAndRules.push({
          value: "",
          rule: "",
        });
      return valuesAndRules;
    };

    const yearGoals = (data.year_goals || []).map((obj) => ({
      goal: sanitize(obj.goal),
      reason: sanitize(obj.reason),
    }));
    if (yearGoals.length === 0)
      yearGoals.push({
        goal: "",
        reason: "",
      });

    return {
      progress: {
        2: data.progress_details?.[2] || 0,
        3: data.progress_details?.[3] || 0,
        4: data.progress_details?.[4] || 0,
        5: data.progress_details?.[5] || 0,
      },

      powerVirtues,
      primaryQuestion: {
        old: sanitize(data.old_primary_question),
        new: sanitize(data.new_primary_question),
      },

      presentTowardsValues: sanitizeValuesAndRules(
        data.old_towards_value_and_rules
      ),
      presentAwayValues: sanitizeValuesAndRules(data.old_away_value_and_rules),

      yearGoals,
      relationshipVision: sanitize(data.relationship_vision_description),

      newTowardsValues: sanitizeValuesAndRules(
        data.new_towards_value_and_rules
      ),
      newAwayValues: sanitizeValuesAndRules(data.new_away_value_and_rules),
      newDestiny: sanitize(data.destiny_changes),
      missionStatement: sanitize(data.mission_statement),
    };
  }, [client]);

  const lastUpdate = useMemo(() => {
    const dateString = client?.answers?.[0]?.modified_at;
    if (!dateString) return null;
    const date = new Date(dateString);
    if (isNaN(date)) return null;

    let day;
    if (isToday(date)) {
      day = "today";
    } else if (isYesterday(date)) {
      day = "yesterday";
    } else {
      day = format(date, "d MMM");
      if (!isThisYear(date)) day += ` ${date.getFullYear()}`;
    }
    return `${day} at ${format(date, "h:mm a")}`;
  }, [client]);

  useEffect(() => {
    if (!posterPlaceholderRef?.current) {
      return;
    }
    posterPlaceholderRef.current.innerHTML = "";
    if (!isPosterVisible) {
      return;
    }
    setIsPosterLoading(true);
    const drawPosterAsync = async () => {
      const user = {
        first_name: client.first_name,
        last_name: client.last_name,
        group_name: client.group_name,
        ...client?.answers?.[0],
      };
      await drawPoster(
        user,
        POSTER_STYLE.BLUE,
        posterPlaceholderRef.current,
        false
      );
      setIsPosterLoading(false);
    };
    setTimeout(drawPosterAsync, 100); // otherwise "Loading..." will not be shown until poster renders
  }, [client, isPosterVisible, posterPlaceholderRef]);

  return (
    <div className={style.Admin}>
      <AdminNav />

      {loading && !client && (
        <div className={style.Loading}>Loading data...</div>
      )}

      {!loading && !client && (
        <div className={style.Loading}>Client not found</div>
      )}

      {client && (
        <>
          <div className={style.TitleContainer}>
            <div className={style.ClientTitle}>
              <Link to={ROUTE_ADMIN} aria-label="List of clients">
                <BackIcon role="presentation" />
              </Link>
              <h1>
                {fullName}
                {client.is_client_at_risk && (
                  <span className={style.FlaggedMark}>!</span>
                )}
              </h1>
              <div className={style.ClientTitleEmail}>
                {client.email}
                {client.group_name && (
                  <span>
                    &nbsp;&nbsp;—&nbsp; Group {client.group_name}
                    {lastUpdate && (
                      <>&nbsp;&nbsp;—&nbsp; Updated {lastUpdate}</>
                    )}
                  </span>
                )}
              </div>
            </div>
            <div className={style.Controls}>
              <label className={style.ClientFlagged}>
                Flagged
                <input
                  type="checkbox"
                  className={style.Switch}
                  checked={client.is_client_at_risk}
                  onChange={handleFlag}
                />
              </label>
              <button
                className={style.ButtonWithBorder}
                onClick={() => setCommentsVisible(!commentsVisible)}
              >
                <CommentsIcon role="presentation" /> Comments
              </button>
              <AdminRefreshButton
                onClick={refresh}
                aria-label="Refresh data"
                title="Refresh data"
                loading={loading}
              />
            </div>
          </div>

          <div className={style.ClientNotes}>
            <textarea
              placeholder="Add here notes about this participant"
              rows={3}
              value={client.trainer_private_notes || ""}
              onInput={handleClientNotesChange}
            />
          </div>

          <CommentsPanel
            clientId={clientId}
            visible={commentsVisible}
            onClose={() => setCommentsVisible(false)}
          />

          <section className={cc([style.Day, style.Day2])}>
            {progress("Day 2", data.progress[2])}
            <div className={style.DayOverview}>
              <div className={style.PowerVirtues}>
                <h3>Power Virtues</h3>
                <ol>
                  {data.powerVirtues.map((virtue, i) => (
                    <li key={i}>{checkMissing(virtue)}</li>
                  ))}
                </ol>
              </div>
              <div className={style.PrimaryQuestion}>
                <h3>Primary Question</h3>
                <div>
                  <h4>Old</h4>
                  <p>{checkMissing(data.primaryQuestion.old)}</p>
                  <h4>New</h4>
                  <p>{checkMissing(data.primaryQuestion.new)}</p>
                </div>
              </div>
            </div>
          </section>

          <hr className={cc([style.Divider])} />

          <section className={cc([style.Day, style.Day3])}>
            {progress("Day 3", data.progress[3])}
            <div className={style.DayOverview}>
              <table>
                <thead>
                  <tr>
                    <th>Present Towards Values</th>
                    <th>Rules</th>
                  </tr>
                </thead>
                <tbody>
                  {data.presentTowardsValues.map(({ value, rule }, i) => (
                    <tr key={i}>
                      <td>{checkMissing(value)}</td>
                      <td>{checkMissing(rule)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <table>
                <thead>
                  <tr>
                    <th>Present Away Values</th>
                    <th>Rules</th>
                  </tr>
                </thead>
                <tbody>
                  {data.presentAwayValues.map(({ value, rule }, i) => (
                    <tr key={i}>
                      <td>{checkMissing(value)}</td>
                      <td>{checkMissing(rule)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>

          {/* <hr className={cc([style.Divider, style.Tomorrow])}/> */}
          <hr className={cc([style.Divider])} />

          <section className={cc([style.Day, style.Day4])}>
            {progress("Day 4", data.progress[4])}
            <div className={style.DayOverview}>
              <table className={style.AwayValues}>
                <thead>
                  <tr>
                    <th>One-Year Goals</th>
                    <th>Reasons</th>
                  </tr>
                </thead>
                <tbody>
                  {data.yearGoals.map(({ goal, reason }, i) => (
                    <tr key={i}>
                      <td>{checkMissing(goal)}</td>
                      <td>{checkMissing(reason)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <h3>Relationship Vision</h3>
              <p>{checkMissing(data.relationshipVision)}</p>
            </div>
          </section>

          <hr className={cc([style.Divider])} />

          <section className={cc([style.Day, style.Day5])}>
            {progress("Day 5", data.progress[5])}
            <div className={style.DayOverview}>
              <table>
                <thead>
                  <tr>
                    <th>New Towards Values</th>
                    <th>Rules</th>
                  </tr>
                </thead>
                <tbody>
                  {data.newTowardsValues.map(({ value, rule }, i) => (
                    <tr key={i}>
                      <td>{checkMissing(value)}</td>
                      <td>{checkMissing(rule)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <table>
                <thead>
                  <tr>
                    <th>New Away Values</th>
                    <th>Rules</th>
                  </tr>
                </thead>
                <tbody>
                  {data.newAwayValues.map(({ value, rule }, i) => (
                    <tr key={i}>
                      <td>{checkMissing(value)}</td>
                      <td>{checkMissing(rule)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <h3>New Destiny</h3>
              <p>{checkMissing(data.newDestiny)}</p>
              <h3>Mission Statement</h3>
              <p>{checkMissing(data.missionStatement)}</p>
            </div>
          </section>

          <section className={style.Poster}>
            <button
              className={style.ButtonWithBorder}
              onClick={handleTogglePoster}
            >
              Toggle Poster Preview
            </button>
            {isPosterLoading && <div>Loading...</div>}
            <div ref={posterPlaceholderRef} />
          </section>
        </>
      )}
    </div>
  );
};

export default AdminClient;
