import React from "react";
import cc from "classcat";

import style from "./index.module.scss";

const COPYRIGHT_STRING = `©${new Date().getFullYear()} Robbins Research International, Inc. All rights reserved.`;

const LeftPanel = ({
  className,
  children,
  title,
  description,
  reversed,
  footer,
  quote,
  ...props
}) => (
  <div
    {...props}
    className={cc([
      className,
      style.LeftPanel,
      {
        [style.LeftPanelReversed]: reversed,
      },
    ])}
  >
    <div className={style.LeftPanelWrapper}>
      {title && <h1 className={style.LeftPanelTitle}>{title}</h1>}
      {description && (
        <div className={style.LeftPanelDescription}>{description}</div>
      )}
      {children}

      <div className={style.LeftPanelFooter}>
        {quote && (
          <blockquote className={style.LeftPanelQuote}>
            <p>
              “{quote}” <cite>Tony Robbins</cite>
            </p>
          </blockquote>
        )}
        {footer}
        <small className={style.LeftPanelCopyright}>{COPYRIGHT_STRING}</small>
      </div>
    </div>
  </div>
);

export default LeftPanel;
