import React from "react";
import cc from "classcat";
import style from "./index.module.scss";
import { ReactComponent as ErrorIcon } from "../../images/icons/error.svg";

const ErrorMessage = ({ className, error }) => (
  <div className={cc([className, style.ErrorMessage])}>
    <ErrorIcon className={style.ErrorMessageIcon} />
    <p className={style.ErrorMessageText}>{error}</p>
  </div>
);

export default ErrorMessage;
