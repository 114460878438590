import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  Fragment,
} from "react";
import { generatePath, Link } from "react-router-dom";
import cc from "classcat";
import AuthService from "../../services/auth.service";
import {
  flagClient,
  getClients,
  resetClientsCache,
} from "../../services/admin.service";
import { ROUTE_ADMIN_CLIENT } from "../../App";
import ProgressCircle from "../../components/ProgressCircle";
import AdminNav from "../../components/AdminNav";
import AdminRefreshButton from "../../components/AdminRefreshButton";
import { ReactComponent as SearchIcon } from "../../images/icons/search.svg";
import { ReactComponent as CancelIcon } from "../../images/icons/cancel.svg";
import { ReactComponent as BackIcon } from "../../images/icons/back-small.svg";
import style from "./index.module.scss";

const ATTENDEES_PER_PAGE = 20;
const PAGE_BUTTONS = 7;

let pageLastValue = null;
let filterLastValue = null;

export function resetAdminCache() {
  pageLastValue = null;
  filterLastValue = null;
  resetClientsCache();
}

function fullName(client) {
  return `${client.first_name} ${client.last_name}`;
}

function clientsSorter(a, b) {
  let comparison = (a.last_name || "").localeCompare(b.last_name || "");
  if (comparison !== 0) return comparison;
  comparison = (a.first_name || "").localeCompare(b.first_name || "");
  if (comparison !== 0) return comparison;
  return a.email.localeCompare(b.email);
}

const Admin = () => {
  const superUser = useMemo(() => AuthService.isSuperUser(), []);
  const group = useMemo(() => AuthService.getUserGroup(), []);

  const [firstLoading, setFirstLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [page, _setPage] = useState(0);
  const setPage = useCallback((newPage) => {
    _setPage(newPage);
    pageLastValue = newPage;
  }, []);
  const [filter, _setFilter] = useState("");
  const setFilter = useCallback(
    (newFilter) => {
      _setFilter(newFilter);
      filterLastValue = newFilter;
      setPage(0);
    },
    [setPage]
  );

  const [clients, setClients] = useState({});
  useEffect(() => {
    if (!firstLoading) return;
    getClients().then((clients) => {
      setClients(clients);
      setLoading(false);
      setFirstLoading(false);
      // IMPORTANT: filter must be restored BEFORE the page, otherwise there
      // can be glitches with the code that fixes the page number if it is
      // greater than the number of pages for the current filter
      if (filterLastValue) _setFilter(filterLastValue);
      if (pageLastValue) _setPage(pageLastValue);
    });
  }, [firstLoading]);

  const handleRefresh = useCallback(() => {
    setLoading(true);
    getClients(true).then((clients) => {
      setClients(clients);
      setLoading(false);
    });
  }, []);

  const handleFlag = useCallback((e) => {
    flagClient(e.target.dataset.clientId, e.target.checked);
    getClients().then(setClients);
  }, []);

  const flaggedClients = useMemo(() => {
    const newArr = Object.values(clients).filter(
      (user) => user.is_client_at_risk
    );
    newArr.sort(clientsSorter);
    return newArr;
  }, [clients]);

  const filteredClients = useMemo(() => {
    const newArr = Object.values(clients).filter((client) => {
      const filterLowerCase = filter.toLowerCase();
      return (
        fullName(client).toLowerCase().includes(filterLowerCase) ||
        client.email.toLowerCase().includes(filterLowerCase) ||
        (client.group_name || "").toLowerCase().includes(filterLowerCase)
      );
    });
    newArr.sort(clientsSorter);
    return newArr;
  }, [clients, filter]);

  const clientsList = useMemo(() => {
    if (superUser && !filter) {
      return flaggedClients;
    }
    return filteredClients;
  }, [superUser, flaggedClients, filter, filteredClients]);

  const pages = useMemo(() => {
    const pages = Math.max(
      1,
      Math.ceil(clientsList.length / ATTENDEES_PER_PAGE)
    );
    // Ensure the current page is not greater than the number of pages.
    // Can happen when the number of clients in the list changes.
    if (page > pages - 1) setPage(pages - 1);
    return pages;
  }, [clientsList, page, setPage]);

  const pagesList = useMemo(() => {
    const pagesList = [];

    if (pages <= PAGE_BUTTONS) {
      for (let i = 0; i < pages; i++) pagesList.push([`page${i}`, i]);
    } else {
      let leftmostPage = Math.max(0, page - Math.floor(PAGE_BUTTONS / 2));
      if (leftmostPage + PAGE_BUTTONS >= pages) {
        leftmostPage = pages - PAGE_BUTTONS;
      }

      for (let i = leftmostPage; i < leftmostPage + PAGE_BUTTONS; i++)
        pagesList.push([`page${i}`, i]);

      if (pagesList[0][1] !== 0) {
        pagesList[0] = ["page0", 0];
        pagesList[1] = ["ellipsis0", "…"];
      }
      if (pagesList[PAGE_BUTTONS - 1][1] !== pages - 1) {
        pagesList[PAGE_BUTTONS - 2] = ["ellipsis1", "…"];
        pagesList[PAGE_BUTTONS - 1] = [`page${pages - 1}`, pages - 1];
      }
    }

    return pagesList;
  }, [page, pages]);

  const clientsInPage = useMemo(() => {
    const pageStart = page * ATTENDEES_PER_PAGE;
    const pageEnd = pageStart + ATTENDEES_PER_PAGE;
    return clientsList.slice(pageStart, pageEnd);
  }, [clientsList, page]);

  return (
    <div className={style.Admin}>
      <AdminNav>
        {Object.keys(clients).length > 0 && (
          <>
            <label className={style.Search}>
              <SearchIcon className={style.SearchIcon} role="presentation" />
              <div className={style.SearchContainer}>
                <input
                  type="text"
                  value={filter}
                  onInput={(e) => setFilter(e.target.value)}
                  placeholder="Search All Users"
                  disabled={firstLoading}
                />
                <button
                  className={style.SearchCancel}
                  aria-label="Cancel search"
                  title="Cancel search"
                  onClick={() => setFilter("")}
                  style={{ visibility: filter === "" && "hidden" }}
                >
                  <CancelIcon role="presentation" />
                </button>
              </div>
            </label>
            <div className={style.FlaggedCount}>
              <span>{flaggedClients.length}</span> Flagged
            </div>
          </>
        )}
      </AdminNav>

      <main>
        <div className={style.TitleContainer}>
          <h1>
            {clientsList === flaggedClients ? (
              "Flagged Clients"
            ) : filter ? (
              <>
                Search Results for <span>{filter}</span>
                <button
                  className={style.SearchCancel}
                  aria-label="Cancel search"
                  title="Cancel search"
                  onClick={() => setFilter("")}
                >
                  <CancelIcon role="presentation" />
                </button>
              </>
            ) : (
              `Group ${group || "(no group assigned)"}`
            )}
          </h1>
          <div className={style.Pages}>
            {pages > 1 && (
              <>
                <button
                  className={style.PrevPage}
                  onClick={() => setPage(Math.max(0, page - 1))}
                  aria-label="Previous page"
                  title="Previous page"
                  disabled={page === 0}
                >
                  <BackIcon role="presentation" />
                </button>
                {pagesList.map(([key, val]) => (
                  <Fragment key={key}>
                    {val === "…" ? (
                      <span>…</span>
                    ) : (
                      <button
                        className={cc(val === page && style.PageCurrent)}
                        onClick={() => setPage(val)}
                        aria-label={`Go to page ${val + 1}`}
                        title={`Go to page ${val + 1}`}
                      >
                        {val + 1}
                      </button>
                    )}
                  </Fragment>
                ))}
                <button
                  className={style.NextPage}
                  onClick={() => setPage(Math.min(pages - 1, page + 1))}
                  aria-label="Next page"
                  title="Next page"
                  disabled={page === pages - 1}
                >
                  <BackIcon
                    role="presentation"
                    style={{ transform: "scaleX(-1)" }}
                  />
                </button>
              </>
            )}
            <AdminRefreshButton
              className={style.RefreshButton}
              onClick={handleRefresh}
              aria-label="Refresh data"
              title="Refresh data"
              loading={loading}
            />
          </div>
        </div>
        {firstLoading && <div className={style.Loading}>Loading data...</div>}
        {clientsList.length === 0 && !loading && (
          <div className={style.Loading}>No client matches this filter.</div>
        )}
        {!firstLoading && clientsList.length > 0 && (
          <>
            <table className={style.ClientsTable}>
              <thead>
                <tr>
                  <th>Client</th>
                  {superUser && <th>Group</th>}
                  {[2, 3, 4, 5].map((i) => (
                    <th key={i}>Day {i}</th>
                  ))}
                  <th>Flagged</th>
                </tr>
              </thead>
              <tbody>
                {clientsInPage.map((client) => (
                  <tr
                    key={client.id}
                    className={cc(client.is_client_at_risk && style.Flagged)}
                  >
                    <th>
                      <Link
                        to={generatePath(ROUTE_ADMIN_CLIENT, {
                          clientId: client.id,
                        })}
                      >
                        <span className={style.Name}>
                          {client.first_name && client.last_name
                            ? fullName(client)
                            : "(no name)"}
                        </span>
                        {client.is_client_at_risk && (
                          <span
                            className={style.FlaggedMark}
                            role="presentation"
                          >
                            !
                          </span>
                        )}
                        <div className={style.Email}>{client.email}</div>
                      </Link>
                    </th>
                    {superUser && <td>{client.group_name}</td>}
                    {[2, 3, 4, 5].map((i) => (
                      <td key={i}>
                        <ProgressCircle
                          size={40}
                          strokeSize={8}
                          perc={client.answers?.[0]?.progress_details?.[i]}
                        />
                      </td>
                    ))}
                    <td>
                      <input
                        className={style.Switch}
                        type="checkbox"
                        data-client-id={client.id}
                        checked={client.is_client_at_risk}
                        onChange={handleFlag}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </main>
    </div>
  );
};

export default Admin;
