import React from "react";
import cc from "classcat";
import AuthService from "../../services/auth.service";
import logo from "../../images/logo.png";
import style from "./index.module.scss";

const AdminNav = ({ className, children, ...props }) => (
  <nav className={cc([style.Nav, className])} {...props}>
    <img src={logo} alt="Date With Destiny" />
    <div>
      {children}
      <button className={style.Logout} onClick={() => AuthService.logout()}>
        Logout
      </button>
    </div>
  </nav>
);

export default AdminNav;
