import React from "react";
import cc from "classcat";

import ProgressSidebar from "../ProgressSidebar";

import style from "./index.module.scss";

const Page = ({ className, children, day, ...props }) => (
  <div
    {...props}
    className={cc([
      className,
      style.Page,
      {
        [style[`PageDay${day}`]]: day,
      },
    ])}
  >
    <ProgressSidebar day={day} />
    {children}
  </div>
);

export default Page;
