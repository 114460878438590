import { format } from "date-fns";

export async function fakeLoading() {
  await new Promise((resolve) => setTimeout(resolve, 400));
}

export function formatDateRange(startDate, endDate) {
  // By default return range equivalent to Dec 4–1, 2021
  let formats = ["MMM d", "d, yyyy"];

  // If years are different, return range equivalent to Dec 9, 2021– Jan 9, 2022
  if (startDate.getFullYear() !== endDate.getFullYear()) {
    formats = ["MMM d, yyyy", "MMM d, yyyy"];
  } else if (startDate.getMonth() !== endDate.getMonth()) {
    formats = ["MMM d", "MMM d, yyyy"];
  }

  return [format(startDate, formats[0]), format(endDate, formats[1])];
}

export function formatDate (date) {
  return format(date, "yyyy-MM-dd");
}

export function normalizePosterData (user) {
  return {
    first_name: user.first_name,
    last_name: user.last_name,
    group_name: user?.group?.name,
    mission_statement: user?.answer?.mission_statement,
    new_away_value_and_rules: user?.answer?.new_away_value_and_rules,
    new_primary_question: user?.answer?.new_primary_question,
    new_towards_value_and_rules: user?.answer?.new_towards_value_and_rules,
    power_virtues: user?.answer?.power_virtues,
    relationship_vision_description: user?.answer?.relationship_vision_description,
    year_goals: user?.answer?.year_goals,
  };
}