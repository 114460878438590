import api from "./api.service";

export const SHARED_CHECKPOINT_OPTIONS = {
  THIRD_DAY: "THIRD_DAY",
  FIFTH_DAY: "FIFTH_DAY",
  DESTINY: "DESTINY",
};

export const SHARING_CHECKPOINT_DAY_IDS = {
  PRESENT_VALUES: "3",
  NEW_VALUES: "5",
  DESTINY: "destiny",
};

export const SHARED_PROPERTY_NAMES = {
  OLD_AWAY: 'old_away_value_and_rules',
  OLD_TOWARDS: 'old_towards_value_and_rules',
  NEW_AWAY: 'new_away_value_and_rules',
  NEW_TOWARDS: 'new_towards_value_and_rules',
  MISSION_STATEMENT: 'mission_statement',
};

/**
 * Get shared progress
 */
const getSharedProgress = async (hash) => {
  try {
    const response = await api().get(`/progress/shared?hash=${hash}`);
    return response.data;
  } catch ({response}) {
    if (response.status === 404) {
      return null;
    }
    const [error] = response?.data?.errors || [];
    throw new Error(error?.message);
  }
}

const shareProgress = async (checkpoint) => {
  try {
    const response = await api().post('/progress', {
      checkpoint: checkpoint.toLowerCase(),
    });
    return response.data;
  } catch ({response}) {
    const [error] = response?.data?.errors || [];
    throw new Error(error?.message);
  }
}

const SharingService = {
  getSharedProgress,
  shareProgress,
};

export default SharingService;
