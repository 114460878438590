import { SVG } from "@svgdotjs/svg.js";
import posterTemplates from "./poster.templates";

import {
  DEFAULT_FONT_COLOR,
  DEFAULT_FONT_FAMILY,
  DEFAULT_FONT_STYLE,
  getConfig,
} from "./poster.config";

const FONT_SIZE_STEP = 0.125;
const TEXT_TO_BBOX_FACTOR = 2;
const TEXT_TO_BBOX_THRESHOLD = 40;
const TEXT_TO_BBOX_SQUARE_RATIO = 11;

const splitStringToFitBoundingBox = (source, bboxWidth, fontStyle, draw) => {
  const result = [];
  const words = source.split(" ");
  let currentLineWords = [];
  for (let wordIndex = 0; wordIndex < words.length; ) {
    const word = words[wordIndex];
    const nextLineWords = [...currentLineWords, word];
    const nextLineText = draw.text(nextLineWords.join(" "));
    nextLineText.font({
      size: fontStyle.size,
      family: fontStyle.family,
    });
    const nextLineWidth = nextLineText.bbox().w;
    nextLineText.remove();

    if (nextLineWidth >= bboxWidth * 0.9 && currentLineWords.length) {
      // next line overflows the container => previous line is max width => attach prev line
      const currentLineText = draw.text(currentLineWords.join(" "));
      currentLineText.font(fontStyle);
      const currentLineWidth = currentLineText.bbox().w;
      currentLineText.remove();
      if (currentLineWidth > bboxWidth) {
        // potentially cut trailing symbols and replace with ellipsis
      }
      result.push(currentLineWords);
      currentLineWords = [];
    } else if (nextLineWidth >= bboxWidth * 0.9 && !currentLineWords.length) {
      // first word of a new line is wider than box width (super-long line with no breaks): split it
      const ratio = nextLineWidth / (bboxWidth * 0.9);
      const splitIntoWordsOfLength = Math.floor(word.length / ratio);
      const splitLongWord = word.match(
        new RegExp(`.{1,${splitIntoWordsOfLength}}`, "g")
      );
      words.splice(wordIndex, 1, ...splitLongWord);
    } else {
      currentLineWords.push(word);
      wordIndex += 1;
    }
  }

  result.push(currentLineWords);

  return result;
};

const formatters = {
  LIST: (container, source, box, fontStyle) => {
    const text = container.text((add) => {
      source.forEach((valueText, i) => {
        const textWithBullet = `${i + 1}. ${valueText}`;
        const paragraphs = textWithBullet
          .replace(/\r/g, "")
          .split("\n")
          .map((paragraph) =>
            splitStringToFitBoundingBox(
              paragraph,
              box.width,
              fontStyle,
              container
            )
          );
        const split = paragraphs.flat();
        let span;
        split.forEach((line, j) => {
          span = add.tspan(line.join(" "));
          if (j < split.length || i < source.length) {
            span.newLine();
          }
        });
      });
      add.font({
        fill: fontStyle.fill || DEFAULT_FONT_COLOR,
        family: fontStyle.family || DEFAULT_FONT_FAMILY,
      });
    });
    return text;
  },
  LIST_DOUBLE: (container, source, box, fontStyle) =>
    container.text((add) => {
      source.forEach((valueDoubleText, i) => {
        valueDoubleText.forEach((valueText, k) => {
          if (!valueText) valueText = " "; // Shows a new line rather than null when values are missing
          const textWithBullet =
            k === 0 ? `${i + 1}. ${valueText}` : `${valueText}`;
          const paragraphs = textWithBullet
            .replace(/\r/g, "")
            .split("\n")
            .map((paragraph) =>
              splitStringToFitBoundingBox(
                paragraph,
                box.width,
                fontStyle,
                container
              )
            );
          const split = paragraphs.flat();
          let span;
          split.forEach((line, j) => {
            span = add.tspan(line.join(" "));
            span.font({
              fill: k === 1 ? fontStyle.secondaryFill : fontStyle.fill,
              family: fontStyle.family || DEFAULT_FONT_FAMILY,
            });
            if (j < split.length && i < source.length) {
              span.newLine();
            }
          });
        });
      });
    }),
  SINGLE: (container, source = "", box, fontStyle) =>
    container.text((add) => {
      const paragraphs = source
        .replace(/\r/g, "")
        .split("\n")
        .map((paragraph) =>
          splitStringToFitBoundingBox(
            paragraph,
            box.width,
            fontStyle,
            container
          )
        );
      const split = paragraphs.flat();
      let span;
      split.forEach((line, i) => {
        span = add.tspan(line.join(" "));
        if (i < split.length) {
          span.newLine();
        }
      });
      add.font(fontStyle);
    }),
};

const addTextToContainer = (container, config, configName, user) => {
  const valueConfig = config[configName];
  const {
    source: sourceProperty,
    box,
    fontStyle: providedFontStyle,
    mustAlignHorizontalCenter,
    mustAlignVerticalCenter,
    mustFitContainer,
    type,
  } = valueConfig;

  const source =
    typeof sourceProperty === "string"
      ? user?.answer?.[sourceProperty]
      : sourceProperty(user);

  const symbol = container.group();
  container.add(symbol);
  symbol.translate(box.left, box.top);
  const rect = symbol.rect(box.width, box.height);
  if (config.IS_DEBUG) {
    rect.fill("rgba(255, 0, 0, 0.5)");
  } else {
    rect.fill("rgba(0, 0, 0, 0.0)"); // explicitly set to transparent, black by default
  }

  const fontStyle = { ...(providedFontStyle || DEFAULT_FONT_STYLE) };

  const formatter = formatters[type];
  if (!formatter) {
    console.error(`No formatter for type ${type}`);
    return;
  }

  let textBbox;
  let text;
  let iteration = 0;
  let nextFontSizeStepRatio = 0;

  do {
    if (text) {
      text.remove();
    }
    const nextFontSize =
      fontStyle.size - FONT_SIZE_STEP * nextFontSizeStepRatio;
    if (config.IS_DEBUG) {
      console.log(`iteration ${iteration++}; nextFontSize: ${nextFontSize}`);
    }
    fontStyle.size = nextFontSize;
    text = formatter(container, source, box, fontStyle);
    if (fontStyle) {
      text.font({
        size: nextFontSize,
      });
    }
    textBbox = text.bbox();
    const currentIterationSquare = textBbox.h * textBbox.w;
    const scaleToBoxSizeSquare =
      currentIterationSquare / (box.width * box.height);
    const missingSteps = Math.ceil(
      ((scaleToBoxSizeSquare * 10) / Math.sqrt(TEXT_TO_BBOX_SQUARE_RATIO)) *
        TEXT_TO_BBOX_FACTOR
    );

    if (config.IS_DEBUG) {
      console.log("missingSteps", missingSteps);
    }
    if (missingSteps > 0) {
      nextFontSizeStepRatio = Math.min(missingSteps, TEXT_TO_BBOX_THRESHOLD);
    }
  } while (mustFitContainer && textBbox.h > box.height);

  text.addTo(symbol);

  if (mustAlignHorizontalCenter) {
    // For some reason calling bbox().w returns a slightly incorrect value, that
    // if used to compute the x coord of the text results in a not perfectly
    // centered text; this alternative method instead works perfectly
    text.x(box.width / 2);
    text.font({ anchor: "middle" });
  }
  if (mustAlignVerticalCenter) {
    const topAlign = (box.height - text.bbox().h) / 2;
    text.y(topAlign);
  } else {
    text.y(0);
  }
};

const imageToDataUrl = async (imageSrc) => {
  const blob = await fetch(imageSrc).then((response) => response.blob());
  const dataUrl = await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
  return dataUrl;
};

export const drawPoster = async (
  user,
  posterStyle,
  $placeholder,
  mustScroll = true
) => {
  const config = getConfig(posterStyle);
  if (config.IS_DEBUG) {
    console.log(user);
  }

  while ($placeholder.firstChild) {
    $placeholder.removeChild($placeholder.firstChild);
  }

  const { width, height } = config;

  const container = SVG().addTo($placeholder);

  container.viewbox(`0 0 ${width} ${height}`);

  if (config.backgroundColor) {
    container.rect(width, height).fill(config.backgroundColor);
  }

  // Images must be added as data-urls because for some browsers limitations
  // images added just with the url are lost when this SVG gets converted to a
  // PNG (conversion performed when the user clicks the Save button to download
  // the poster)
  const backgroundDataUrl = await imageToDataUrl(config.backgroundImage);
  const image = container.image(backgroundDataUrl);
  image.size(width, height);
  if (config.backgroundOpacity) {
    image.css({
      "mix-blend-mode": "multiply",
      opacity: config.backgroundOpacity,
    });
  }

  container.svg(posterTemplates[posterStyle] + posterTemplates.font);
  [
    "away",
    "goals",
    "mission",
    "name",
    "power",
    "primary",
    "relationship",
    "team",
    "towards",
  ].forEach((key) => addTextToContainer(container, config, key, user));

  if (mustScroll) {
    container.width(width * 2);
    container.height(height * 2);
  } else {
    container.width("100%");
  }

  if (mustScroll) {
    const $html = document.getElementsByTagName("html")[0];
    const screenWidth = $html.clientWidth;
    const screenHeight = $html.clientHeight;

    setTimeout(() => {
      window.scrollTo(width - screenWidth / 2, height - screenHeight / 2);
    }, 500); // prevent browser from scrolling back to its scroll position on page refresh
  }

  return container;
};
