import polyBackground from "../../images/poly-background.svg";
import simplySageBackground from "../../images/poster/poster-simply-sage.svg";
import woodlandWaveCloudBackground from "../../images/poster/poster-woodland-wave.svg";
import boldlyBrushedCloudBackground from "../../images/poster/poster-boldly-brushed.png";
import strikingSurrealCloudBackground from "../../images/poster/poster-striking-surreal.svg";
import creativityCloudBackground from "../../images/poster/poster-creativity-cloud.png";
import style from "./index.module.scss";

const IS_DEBUG = false;

export const POSTER_STYLE = {
  BLUE: "blue",
  GREEN: "green",
  ORANGE: "orange",
  RED: "red",
  PURPLE: "purple",
  SIMPLY_SAGE: "simply-sage",
  WOODLAND_WAVE: "woodland-wave",
  BOLDLY_BRUSHED: "boldly-brushed",
  STRIKING_SURREAL: "striking-surreal",
  CREATIVITY_CLOUD: "creativity-cloud",
};

const SIMPLE_POSTER_STYLES = [
  POSTER_STYLE.BLUE,
  POSTER_STYLE.GREEN,
  POSTER_STYLE.ORANGE,
  POSTER_STYLE.RED,
  POSTER_STYLE.PURPLE,
];

export const DEFAULT_FONT_FAMILY = "dwd";
export const DEFAULT_FONT_SIZE = 20;
export const DEFAULT_FONT_COLOR = "white";
export const SECONDARY_FONT_COLOR = "rgba(255, 255, 255, 0.75)";
export const DEFAULT_FONT_STYLE = {
  family: DEFAULT_FONT_FAMILY,
  size: DEFAULT_FONT_SIZE,
  fill: DEFAULT_FONT_COLOR,
  secondaryFill: SECONDARY_FONT_COLOR,
};

const getPosterBackgroundColor = (theme) => {
  const colors = {
    [POSTER_STYLE.BLUE]: style.theme1BgColor,
    [POSTER_STYLE.GREEN]: style.theme2BgColor,
    [POSTER_STYLE.ORANGE]: style.theme3BgColor,
    [POSTER_STYLE.RED]: style.theme4BgColor,
    [POSTER_STYLE.PURPLE]: style.theme5BgColor,
  };
  return colors[theme] || null;
};

const getPosterBackgroundOpacity = (theme) => {
  const options = {
    [POSTER_STYLE.BLUE]: style.theme1BgOpacity,
    [POSTER_STYLE.GREEN]: style.theme2BgOpacity,
    [POSTER_STYLE.ORANGE]: style.theme3BgOpacity,
    [POSTER_STYLE.RED]: style.theme4BgOpacity,
    [POSTER_STYLE.PURPLE]: style.theme5BgOpacity,
  };
  return options[theme] || null;
};

const getPosterBackgroundImage = (theme) => {
  const backgrounds = {
    [POSTER_STYLE.SIMPLY_SAGE]: simplySageBackground,
    [POSTER_STYLE.WOODLAND_WAVE]: woodlandWaveCloudBackground,
    [POSTER_STYLE.BOLDLY_BRUSHED]: boldlyBrushedCloudBackground,
    [POSTER_STYLE.STRIKING_SURREAL]: strikingSurrealCloudBackground,
    [POSTER_STYLE.CREATIVITY_CLOUD]: creativityCloudBackground,
  };
  return backgrounds[theme] || polyBackground;
};

const getFontStyle = (theme) => {
  if (theme === POSTER_STYLE.ORANGE || theme === POSTER_STYLE.BOLDLY_BRUSHED) {
    return {
      ...DEFAULT_FONT_STYLE,
      fill: "rgb(95, 94, 117)",
      secondaryFill: "rgba(95, 94, 117, 0.75)",
    };
  } else {
    return DEFAULT_FONT_STYLE;
  }
};

const getTeamFontStyle = (theme) => {
  if (SIMPLE_POSTER_STYLES.includes(theme)) {
    return {
      family: DEFAULT_FONT_FAMILY,
      size: 32,
      weight: "bold",
      fill: "rgba(0, 0, 0, 0.45)",
    };
  } else {
    const fills = {
      [POSTER_STYLE.SIMPLY_SAGE]: "#1FD9E9",
      [POSTER_STYLE.WOODLAND_WAVE]: "#FECE63",
      [POSTER_STYLE.STRIKING_SURREAL]: "#FDAE01",
    };
    return {
      family: DEFAULT_FONT_FAMILY,
      size: DEFAULT_FONT_SIZE,
      fill: fills[theme] || DEFAULT_FONT_COLOR,
      weight: "bold",
    };
  }
};

const getRichPosterConfig = (theme) => ({
  IS_DEBUG,
  width: 1440,
  height: 960,
  backgroundImage: getPosterBackgroundImage(theme),
  away: {
    source: (data) =>
      (data?.new_away_value_and_rules || []).reduce(
        (result, ruleAndValue) => [
          ...result,
          [ruleAndValue.value, ruleAndValue.rule],
        ],
        []
      ),
    box: {
      width: 396,
      height: 326,
      left: 990,
      top: 263,
    },
    type: "LIST_DOUBLE",
    mustFitContainer: true,
    fontStyle: getFontStyle(theme),
  },
  goals: {
    source: (data) =>
      (data?.year_goals || []).reduce(
        (result, goal) => [...result, goal.goal],
        []
      ),
    box: {
      width: 396,
      height: 117,
      left: 518,
      top: 687,
    },
    type: "LIST",
    mustFitContainer: true,
    fontStyle: getFontStyle(theme),
  },
  mission: {
    source: (data) => data.mission_statement,
    box: {
      width: 860,
      height: 110,
      left: 500,
      top: 48,
    },
    type: "SINGLE",
    mustFitContainer: true,
    mustAlignVerticalCenter: true,
    fontStyle: DEFAULT_FONT_STYLE,
  },
  name: {
    source: (data) => `${data.first_name} ${data.last_name}`,
    box: {
      width: 300,
      height: 45,
      left: 572,
      top: 505,
    },
    type: "SINGLE",
    mustAlignHorizontalCenter: true,
    mustAlignVerticalCenter: true,
    mustFitContainer: true,
    fontStyle: {
      ...DEFAULT_FONT_STYLE,
      weight: "lighter",
    },
  },
  power: {
    source: (data) =>
      (data?.power_virtues || []).reduce(
        (result, virtue) => [...result, virtue.name],
        []
      ),
    box: {
      width: 396,
      height: 117,
      left: 54,
      top: 687,
    },
    type: "LIST",
    mustFitContainer: true,
    fontStyle: getFontStyle(theme),
  },
  primary: {
    source: (data) => data.new_primary_question,
    box: {
      width: 942,
      height: 40,
      left: 442,
      top: 868,
    },
    type: "SINGLE",
    mustFitContainer: true,
    mustAlignVerticalCenter: true,
    fontStyle: getFontStyle(theme),
  },
  relationship: {
    source: (data) => data.relationship_vision_description,
    box: {
      width: 396,
      height: 117,
      left: 990,
      top: 687,
    },
    type: "SINGLE",
    mustFitContainer: true,
    fontStyle: getFontStyle(theme),
  },
  team: {
    source: (data) => (data?.group_name ? `- TEAM ${data.group_name} -` : ""),
    box: {
      width: 300,
      height: 45,
      left: 572,
      top: 550,
    },
    type: "SINGLE",
    mustAlignHorizontalCenter: true,
    mustAlignVerticalCenter: true,
    mustFitContainer: true,
    fontStyle: getTeamFontStyle(theme),
  },
  towards: {
    source: (data) =>
      (data?.new_towards_value_and_rules || []).reduce(
        (result, ruleAndValue) => [
          ...result,
          [ruleAndValue.value, ruleAndValue.rule],
        ],
        []
      ),
    box: {
      width: 396,
      height: 326,
      left: 54,
      top: 263,
    },
    type: "LIST_DOUBLE",
    mustFitContainer: true,
    fontStyle: getFontStyle(theme),
  },
});

const getSimplePosterConfig = (theme) => ({
  IS_DEBUG,
  width: 1440,
  height: 960,
  backgroundColor: getPosterBackgroundColor(theme),
  backgroundImage: getPosterBackgroundImage(theme),
  backgroundOpacity: getPosterBackgroundOpacity(theme),
  mission: {
    source: (data) => data.mission_statement,
    box: {
      left: 240,
      top: 40,
      width: 1155,
      height: 70,
    },
    type: "SINGLE",
    mustFitContainer: true,
    mustAlignVerticalCenter: true,
    fontStyle: DEFAULT_FONT_STYLE,
  },
  towards: {
    source: (data) =>
      (data?.new_towards_value_and_rules || []).reduce(
        (result, ruleAndValue) => [
          ...result,
          [ruleAndValue.value, ruleAndValue.rule],
        ],
        []
      ),
    box: {
      left: 45,
      top: 210,
      width: 450,
      height: 380,
    },
    type: "LIST_DOUBLE",
    mustFitContainer: true,
    fontStyle: DEFAULT_FONT_STYLE,
  },
  name: {
    source: (data) => `${data.first_name} ${data.last_name}`,
    box: {
      left: 565,
      top: 475,
      width: 315,
      height: 50,
    },
    type: "SINGLE",
    mustAlignHorizontalCenter: true,
    mustAlignVerticalCenter: true,
    mustFitContainer: true,
    fontStyle: {
      ...DEFAULT_FONT_STYLE,
      size: 32,
      weight: "bold",
    },
  },
  team: {
    source: (data) => (data?.group_name ? `- TEAM ${data.group_name} -` : ""),
    box: {
      left: 565,
      top: 525,
      width: 315,
      height: 50,
    },
    type: "SINGLE",
    mustAlignHorizontalCenter: true,
    mustAlignVerticalCenter: true,
    mustFitContainer: true,
    fontStyle: getTeamFontStyle(theme),
  },
  away: {
    source: (data) =>
      (data?.new_away_value_and_rules || []).reduce(
        (result, ruleAndValue) => [
          ...result,
          [ruleAndValue.value, ruleAndValue.rule],
        ],
        []
      ),
    box: {
      left: 945,
      top: 210,
      width: 450,
      height: 380,
    },
    type: "LIST_DOUBLE",
    mustFitContainer: true,
    fontStyle: DEFAULT_FONT_STYLE,
  },
  goals: {
    source: (data) =>
      (data?.year_goals || []).reduce(
        (result, goal) => [...result, goal.goal],
        []
      ),
    box: {
      left: 45,
      top: 690,
      width: 450,
      height: 110,
    },
    type: "LIST",
    mustFitContainer: true,
    fontStyle: DEFAULT_FONT_STYLE,
  },
  power: {
    source: (data) =>
      (data?.power_virtues || []).reduce(
        (result, virtue) => [...result, virtue.name],
        []
      ),
    box: {
      left: 565,
      top: 690,
      width: 315,
      height: 110,
    },
    type: "LIST",
    mustFitContainer: true,
    fontStyle: DEFAULT_FONT_STYLE,
  },
  relationship: {
    source: (data) => data.relationship_vision_description,
    box: {
      left: 945,
      top: 690,
      width: 450,
      height: 110,
    },
    type: "SINGLE",
    mustFitContainer: true,
    fontStyle: DEFAULT_FONT_STYLE,
  },
  primary: {
    source: (data) => data.new_primary_question,
    box: {
      left: 470,
      top: 860,
      width: 925,
      height: 60,
    },
    type: "SINGLE",
    mustFitContainer: true,
    mustAlignVerticalCenter: true,
    fontStyle: DEFAULT_FONT_STYLE,
  },
});

export const getConfig = (theme) => {
  if (SIMPLE_POSTER_STYLES.includes(theme)) {
    return getSimplePosterConfig(theme);
  } else {
    return getRichPosterConfig(theme);
  }
};
