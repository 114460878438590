import React, { useState, Fragment } from "react";

import { days } from "../../days.json";

import { ROUTE_AUTH } from "../../App";

import Button from "../../components/Button";
import Checkbox from "../../components/Checkbox";
import Page from "../../components/Page";
import LeftPanel from "../../components/LeftPanel";
import RightPanel from "../../components/RightPanel";

import style from "./index.module.scss";

const DAY_ONE_CONTENT = days[0].welcome_screen;

const Home = () => {
  const [privacyChecked, setPrivacyChecked] = useState(false);

  return (
    <Page className={style.Home}>
      <LeftPanel
        reversed
        title={
          <Fragment>
            <strong>{DAY_ONE_CONTENT.title}</strong> <br />
            {DAY_ONE_CONTENT.sub_title}
          </Fragment>
        }
        description={
          <Fragment>
            {DAY_ONE_CONTENT.description.map((copy, index) => (
              <p key={index}>{copy}</p>
            ))}
          </Fragment>
        }
        quote={DAY_ONE_CONTENT.quote}
      />

      <RightPanel className={style.HomeContent}>
        <div className={style.HomeCopy}>
          <h3>
            What would it be like to have absolute certainty about what you want
            from your life—what you want to do, become, share, give, and create?
          </h3>

          <p>
            <strong>
              Date With Destiny is designed to transform the foundation of your
              everyday experience.
            </strong>
          </p>

          <p>
            During this event, you’ll discover the exact beliefs, values, and
            rules that control virtually every decision you make, every moment
            of your life—including how you treat your body, how you interact in
            your personal and professional relationships, how you make decisions
            about money, and what you achieve and contribute in your mission or
            career.
          </p>

          <Checkbox
            checked={privacyChecked}
            onChange={(e) => setPrivacyChecked(e.target.checked)}
            label={
              <Fragment>
                I agree to the Date With Destiny{" "}
                <a
                  href="https://www.tonyrobbins.com/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </Fragment>
            }
          />

          <Button
            className={style.HomeButton}
            to={ROUTE_AUTH}
            disabled={!privacyChecked}
            onClick={(e) => !privacyChecked && e.preventDefault()}
          >
            Get Started
          </Button>
        </div>
      </RightPanel>
    </Page>
  );
};

export default Home;
