import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Redirect } from "react-router-dom";
import AuthService from "../../services/auth.service";

import { days } from "../../days.json";

import { getDefaultAuthedRoute } from "../../App";

import Page from "../../components/Page";
import LeftPanel from "../../components/LeftPanel";
import RightPanel from "../../components/RightPanel";
import Input from "../../components/Input";
import ErrorMessage from "../../components/ErrorMessage";
import ButtonWithPrompt from "../../components/ButtonWithPrompt";

import style from "./index.module.scss";

const STEP_EMAIL = "email";
const STEP_SUCCESS = "success";

const GENERIC_ERROR = "Something went wrong. Please try again.";

const DAY_ONE_CONTENT = days[0].welcome_screen;

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(STEP_EMAIL);

  const [globalError, setGlobalError] = useState(null);
  const [fieldError, setFieldError] = useState({});

  const [email, setEmail] = useState("");

  // Reset the form error when any input is typed in
  useEffect(() => {
    setGlobalError(null);
  }, [email]);

  const handleError = useCallback((error) => {
    setFieldError(error);
  }, []);

  const handleEmailSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (fieldError) return;

      setLoading(true);
      setGlobalError(null);

      try {
        await AuthService.resetPassword(email);
      } catch (e) {
        const newGlobalError = GENERIC_ERROR;

        setGlobalError(newGlobalError);
        setLoading(false);
        return;
      }

      // Short timeout gives the user a feeling of something happening
      setTimeout(() => {
        setStep(STEP_SUCCESS);

        setLoading(false);
      }, 500);
    },
    [email, fieldError]
  );

  if (AuthService.isAuthenticated()) {
    return <Redirect to={getDefaultAuthedRoute()} />;
  }

  return (
    <Page className={style.Auth} day={1}>
      <LeftPanel
        title={
          <Fragment>
            <strong>{DAY_ONE_CONTENT.title}</strong> <br />
            {DAY_ONE_CONTENT.sub_title}
          </Fragment>
        }
        description={
          <Fragment>
            {DAY_ONE_CONTENT.description.map((copy, index) => (
              <p key={index}>{copy}</p>
            ))}
          </Fragment>
        }
        quote={DAY_ONE_CONTENT.quote}
      />
      <RightPanel single>
        {step === STEP_EMAIL && (
          <form onSubmit={handleEmailSubmit} key={STEP_EMAIL} noValidate>
            <Input
              id="email"
              fieldName="Email"
              label="What is your email address?"
              type="email"
              name="email"
              placeholder="My email address is…"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onError={handleError}
              error={fieldError}
              required
              autoFocus
              autoComplete="email"
              readonly={loading}
            />

            {email && (
              <ButtonWithPrompt
                amendPrompt={false}
                disabled={fieldError}
                loading={loading}
              />
            )}
            {globalError && (
              <ErrorMessage
                className={style.AuthFormError}
                error={<strong>{globalError}</strong>}
              />
            )}
          </form>
        )}
        {step === STEP_SUCCESS && (
          <div className={style.AuthResetSuccess}>
            <p>
              An email with reset instructions was sent to{" "}
              <strong>{email}</strong> if an account for that email address
              exists. Please check your email&nbsp;inbox.
            </p>
          </div>
        )}
      </RightPanel>
    </Page>
  );
};

export default ForgotPassword;
