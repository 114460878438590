import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
  createRef,
  Fragment,
} from "react";
import cc from "classcat";

import { FORBIDDEN_NAVIGATION_MESSAGE } from "../../containers/QuizQuestion";
import { fakeLoading } from "../../utils";
import useFirstRenderEffect from "../../hooks/useFirstRenderEffect";

import Input from "../Input";
import ButtonWithPrompt from "../ButtonWithPrompt";

import { ReactComponent as ArrowDownIcon } from "../../images/icons/arrow-down.svg";
import { ReactComponent as ArrowUpIcon } from "../../images/icons/arrow-up.svg";
import { ReactComponent as DeleteIcon } from "../../images/icons/delete.svg";
import { ReactComponent as PlusIcon } from "../../images/icons/plus.svg";
import { ReactComponent as ReorderIcon } from "../../images/icons/reorder.svg";
import { ReactComponent as NotesIcon } from "../../images/icons/notes.svg";

import style from "./index.module.scss";

const STEP_PRIMARY = "primary";
const STEP_ORDERING = "ordering";
const STEP_SECONDARY = "secondary";

// const REPEAT_STATE_INITIAL = 'initial';
const REPEAT_STATE_REPEAT = "repeat";
const REPEAT_STATE_NEXT = "next";

const TYPE_PRIMARY = "primary";
const TYPE_SECONDARY = "secondary";

const DIRECTION_UP = "up";
const DIRECTION_DOWN = "down";

function moveItem(arr, from, to) {
  const newArr = [...arr];
  const valFrom = newArr[from];
  const valTo = newArr[to];

  newArr[from] = valTo;
  newArr[to] = valFrom;

  return newArr;
}

const SortableInputs = ({
  className,
  fieldId,
  repeatMin: _repeatMin = 4,
  repeatMax: _repeatMax = 4,
  defaultValue,
  value,
  onChange,
  onError,
  onStepChange,
  stepLabelPrefix,
  // Primary props
  primaryLabel,
  primaryKey,
  primaryMin,
  primaryMax,
  primaryPlaceholder,
  primarySubmit,
  primaryOrderingLabel,
  // Secondary props
  secondaryKey,
  secondaryLabel: _secondaryLabel,
  secondaryPlaceholder,
  secondarySubmit = "Next",
  secondaryMin,
  secondaryMax,
  parentFormLoading,
  returningUser,
  ...props
}) => {
  const inputRefs = useRef();
  const settingRef = useRef();

  const [loading, setLoading] = useState(false);
  const [sortableError, setSortableError] = useState({});
  const [step, setStep] = useState(STEP_PRIMARY);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);

  const type = secondaryKey ? TYPE_SECONDARY : TYPE_PRIMARY;

  // Ensures that repeatMin is always smaller or equal to repeatMax
  const repeatMax = Math.max(_repeatMin, _repeatMax);
  const repeatMin = Math.min(_repeatMin, _repeatMax);

  const items = useMemo(() => {
    const items = [];
    const valuesCount = value?.length || 0;

    for (let i = 0; i < repeatMax; i++) {
      const id = `${fieldId}-${i}`;

      let label;

      if (i === 0) {
        label = primaryLabel?.initial;
      } else if (
        i < Math.max(repeatMin, valuesCount) ||
        (valuesCount === repeatMax && i === valuesCount - 1)
      ) {
        label = primaryLabel?.repeat;
      } else {
        label = primaryLabel?.next.replace(
          "%%UP_TO_VALUES%%",
          repeatMax - valuesCount
        );
      }

      if (label) {
        label = label.replace("%%INDEX_1%%", i + 1);
      }

      const item = {
        id,
        label,
      };

      items.push(item);
    }

    return items;
  }, [primaryLabel, fieldId, repeatMin, repeatMax, value?.length]);

  const currentItem = items[currentItemIndex];
  const currentItemValue = value?.[currentItemIndex]?.[primaryKey] || "";

  const repeatState = useMemo(() => {
    if (currentItemIndex === 0) {
      return "initial";
    }
    if (currentItemIndex < repeatMin) {
      return REPEAT_STATE_REPEAT;
    }
    if (currentItemValue && currentItemValue.trim() !== "") {
      return REPEAT_STATE_REPEAT;
    }
    return REPEAT_STATE_NEXT;
  }, [repeatMin, currentItemIndex, currentItemValue]);

  const handleChange = (e, index, key) => {
    e.preventDefault && e.preventDefault();

    const newValue = value ? [...value] : [];

    if (
      step === STEP_PRIMARY &&
      newValue.length > repeatMin &&
      index === newValue.length - 1 &&
      e.target.value.trim() === ""
    ) {
      newValue.splice(index, 1);
    } else {
      if (!newValue[index]) {
        newValue[index] = {
          [primaryKey]: null,
          sort_order: index + 1,
          id: `id-${index}`,
        };

        if (secondaryKey) {
          newValue[index][secondaryKey] = null;
        }
      }

      newValue[index][key] = e.target.value;
    }

    onChange(newValue);
  };

  const handleSortableError = useCallback((error, fieldId) => {
    setSortableError((oldError) => ({
      ...oldError,
      [fieldId]: error,
    }));
  }, []);

  // isValid is false if sortableError contains at least one non-null value
  const isValid = useMemo(
    () => !Object.values(sortableError).some((e) => !!e),
    [sortableError]
  );

  const stepPrimaryLastItemIndex = useMemo(() => {
    let entries = value?.length || 0;
    if (entries < repeatMin) entries = repeatMin;
    if (entries < repeatMax) entries += 1;
    return entries - 1;
  }, [value?.length, repeatMin, repeatMax]);

  useFirstRenderEffect(() => {
    // This needs to be called at the first render because QuizQuestion needs it
    // to load the description of the step inside the left panel
    onStepChange(step, fieldId);
  });

  const goToStep = (toStep, toItemIndex = stepPrimaryLastItemIndex) => {
    if (step === toStep && currentItemIndex === toItemIndex) return;

    const newInputRefs = {};
    const newValue = [];

    value.forEach((v) => {
      if ((v[primaryKey] || "").trim() === "") return;
      newValue.push(v);
      newInputRefs[`primary-${v.id}`] = createRef();
      newInputRefs[`secondary-${v.id}`] = createRef();
    });

    inputRefs.current = newInputRefs;
    onChange(newValue);

    setStep(toStep);
    setCurrentItemIndex(toItemIndex);
    settingRef.current?.focus();

    onStepChange(toStep, fieldId);
  };

  const handleGoToNext = async (e) => {
    e.preventDefault();

    if ((!isValid && currentItemIndex < repeatMin) || loading) return;

    setLoading(true);

    // Small timeout to give the user the feel that something happened when they clicked
    await fakeLoading();

    if (
      currentItemIndex === items.length - 1 ||
      !currentItemValue ||
      currentItemValue.trim() === ""
    ) {
      goToStep(STEP_ORDERING);
    } else {
      goToStep(STEP_PRIMARY, currentItemIndex + 1);
    }

    setLoading(false);
  };

  const handleItemOrder = useCallback(
    (e, oldIndex, direction) => {
      e.preventDefault();

      const newIndex = oldIndex + (direction === DIRECTION_UP ? -1 : 1);

      // moveItem doesn't mutate so it's safe to pass value directly to it
      const newValue = moveItem(value, oldIndex, newIndex).map(
        (item, index) => ({
          ...item,
          sort_order: index + 1,
        })
      );

      onChange(newValue);
    },
    [onChange, value]
  );

  const handleItemDelete = useCallback(
    (e, itemIndex, itemId) => {
      e.preventDefault();

      if (value.length <= repeatMin) return;

      const newValue = [...value];
      newValue.splice(itemIndex, 1);

      // Reset the error for that field when deleted to prevent delete button from being hidden
      handleSortableError(null, itemId);

      onChange(newValue);
    },
    [onChange, handleSortableError, value, repeatMin]
  );

  const handleGoToSecondary = async (e) => {
    e.preventDefault();

    // Small UX timeout
    setLoading(true);
    await fakeLoading();
    setLoading(false);

    goToStep(STEP_SECONDARY);
  };

  useEffect(() => {
    // The error message isn't relevant here as it doesn't need to be shown so can
    // just be set to true if any invalid (for the parent) conditions are met.
    onError(isValid ? null : true, fieldId);
  }, [isValid, onError, fieldId]);

  const navLinks = useMemo(() => {
    const navLinks = [];
    for (let i = 0; i <= stepPrimaryLastItemIndex; i++) {
      navLinks.push({
        step: STEP_PRIMARY,
        itemIndex: i,
        enabled: i === 0 || i <= value?.length,
        isAddLink:
          i === stepPrimaryLastItemIndex &&
          i !== value?.length - 1 &&
          i >= repeatMin,
      });
    }

    const minEntriesAdded = value?.length >= repeatMin;

    navLinks.push({
      step: STEP_ORDERING,
      itemIndex: stepPrimaryLastItemIndex,
      enabled: minEntriesAdded,
    });
    if (secondaryKey) {
      navLinks.push({
        step: STEP_SECONDARY,
        itemIndex: stepPrimaryLastItemIndex,
        enabled: minEntriesAdded,
      });
    }

    return navLinks;
  }, [value, repeatMin, stepPrimaryLastItemIndex, secondaryKey]);

  return (
    <div {...props} className={cc([className, style.Container])}>
      <div className={style.FieldContainer}>
        <div>
          {step === STEP_PRIMARY && (
            <Fragment>
              <Input
                key={currentItemIndex}
                ref={settingRef}
                id="primary"
                label={currentItem.label}
                value={value?.[currentItemIndex]?.[primaryKey] || ""}
                defaultValue={defaultValue?.[currentItemIndex]?.[primaryKey]}
                onChange={(e) => handleChange(e, currentItemIndex, primaryKey)}
                error={sortableError.primary}
                onError={handleSortableError}
                required={currentItemIndex < repeatMin}
                min={primaryMin}
                max={primaryMax}
                autoFocus={true}
                placeholder={primaryPlaceholder}
                readonly={parentFormLoading || loading}
              />
              {/* The button should be shown if there is a value in the item, or if
              the minimum number of items have been met (but is still valid).
              handleGoToNext decides what to do in both instances. */}
              {!sortableError.primary && (
                <ButtonWithPrompt
                  onClick={handleGoToNext}
                  label={primarySubmit?.[repeatState] || "Next"}
                  loading={loading}
                />
              )}
            </Fragment>
          )}

          {step === STEP_ORDERING && (
            <Fragment>
              {/* This label has aria-hidden to avoid duplication of the aria-label content on the input itself */}
              {primaryOrderingLabel && (
                <div className={style.OrderingLabel} aria-hidden="true">
                  {primaryOrderingLabel}
                </div>
              )}
              {value &&
                value.map((item, index) => {
                  const itemId = item.id;

                  return (
                    <div
                      key={itemId}
                      className={cc([
                        style.OrderingItem,
                        {
                          [style.OrderingItemWithDelete]:
                            repeatMin !== repeatMax,
                        },
                      ])}
                    >
                      <div className={style.OrderingItemValue}>
                        <Input
                          ref={inputRefs.current[`primary-${itemId}`]}
                          id={itemId}
                          className={style.OrderingItemInput}
                          aria-label={
                            primaryOrderingLabel || items[index]?.label
                          }
                          number={index + 1}
                          helper={false}
                          value={item?.[primaryKey]}
                          defaultValue={defaultValue?.[index]?.[primaryKey]}
                          onChange={(e) => handleChange(e, index, primaryKey)}
                          onError={handleSortableError}
                          error={sortableError?.[itemId]}
                          min={primaryMin}
                          max={primaryMax}
                          required
                          placeholder={primaryPlaceholder}
                          readonly={parentFormLoading}
                        />
                      </div>

                      <div className={style.OrderingItemButtons}>
                        <button
                          onClick={(e) =>
                            handleItemOrder(e, index, DIRECTION_UP)
                          }
                          disabled={index === 0}
                          aria-label="Move item up"
                        >
                          <ArrowUpIcon role="presentation" />
                        </button>
                        <button
                          onClick={(e) =>
                            handleItemOrder(e, index, DIRECTION_DOWN)
                          }
                          disabled={index === value.length - 1}
                          aria-label="Move item down"
                        >
                          <ArrowDownIcon role="presentation" />
                        </button>
                        {repeatMin !== repeatMax && (
                          <button
                            onClick={(e) => handleItemDelete(e, index, itemId)}
                            aria-label="Delete item"
                            disabled={value.length <= repeatMin}
                          >
                            <DeleteIcon role="presentation" />
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}

              {type === TYPE_SECONDARY && isValid && (
                <ButtonWithPrompt
                  onClick={handleGoToSecondary}
                  label={secondarySubmit}
                  loading={loading}
                />
              )}
            </Fragment>
          )}

          {step === STEP_SECONDARY && (
            <Fragment>
              {value.map((item, index) => {
                const inputId = `secondary-${item.id}`;

                const primaryValue = value[index]?.[primaryKey];
                let secondaryLabel = primaryValue;
                if (_secondaryLabel) {
                  secondaryLabel = _secondaryLabel.replace(
                    "%%PRIMARY_VALUE%%",
                    primaryValue
                  );
                }

                return (
                  <Input
                    className={style.SecondaryItemInput}
                    key={index}
                    id={inputId}
                    ref={inputRefs.current[inputId]}
                    number={index + 1}
                    label={secondaryLabel}
                    autoFocus={index === 0}
                    helper={index > 0 ? false : undefined}
                    error={sortableError[inputId]}
                    onError={handleSortableError}
                    onChange={(e) => handleChange(e, index, secondaryKey)}
                    defaultValue={defaultValue?.[index]?.[secondaryKey]}
                    value={value[index]?.[secondaryKey] || ""}
                    min={secondaryMin}
                    max={secondaryMax}
                    placeholder={secondaryPlaceholder}
                    required={false}
                    readonly={parentFormLoading}
                  />
                );
              })}
            </Fragment>
          )}

          {isValid &&
            step !== STEP_PRIMARY &&
            step === navLinks[navLinks.length - 1].step && (
              <ButtonWithPrompt label="Save" loading={parentFormLoading} />
            )}
        </div>
      </div>

      <div className={style.StepsNav}>
        {navLinks.map((navStep, i) => {
          const active =
            navStep.step === step && navStep.itemIndex === currentItemIndex;
          const disabled = !navStep.enabled;

          return (
            <button
              key={i}
              className={cc([
                active && style.active,
                !isValid && !active && style.forbidNavigation,
              ])}
              type="button"
              disabled={disabled}
              title={isValid ? undefined : FORBIDDEN_NAVIGATION_MESSAGE}
              onClick={
                !isValid || disabled || active
                  ? undefined
                  : () => goToStep(navStep.step, navStep.itemIndex)
              }
            >
              {navStep.step === STEP_PRIMARY &&
                (navStep.isAddLink ? <PlusIcon role="presentation" /> : i + 1)}
              {navStep.step === STEP_ORDERING && (
                <ReorderIcon role="presentation" />
              )}
              {navStep.step === STEP_SECONDARY && (
                <NotesIcon role="presentation" />
              )}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default SortableInputs;
