import React from "react";
import cc from "classcat";

import Button from "../Button";

import style from "./index.module.scss";

const ButtonWithPrompt = ({
  className,
  loading,
  disabled,
  amendPrompt = true,
  // keyPrompt = true,
  onClick,
  label = "Ok",
}) => (
  <div className={cc([className, style.ButtonWithPrompt])}>
    <div className={style.ButtonWithPromptWrapper}>
      <Button
        type="submit"
        disabled={disabled}
        loading={loading}
        onClick={onClick}
      >
        {label}
      </Button>
      {/* This has been removed until it can be properly user tested and integrated across the site
          in an accessible and consistent manner */}
      {/*keyPrompt &&
        <span className={style.ButtonWithPromptKey}>press <strong>Enter</strong> ⏎</span>
      */}
    </div>
    {amendPrompt && (
      <p className={style.ButtonWithPromptAmend}>
        You can come back and amend your answers at any point throughout
        the&nbsp;event.
      </p>
    )}
  </div>
);

export default ButtonWithPrompt;
