import React from "react";
import { Link } from "react-router-dom";
import cc from "classcat";

import Spinner from "../Spinner";

import style from "./index.module.scss";

const Button = ({ disabled, loading, children, className, ...props }) => {
  const ButtonTag = props.to ? Link : "button";

  return (
    <ButtonTag
      className={cc([
        style.Button,
        className,
        {
          [style.ButtonLoading]: loading,
        },
      ])}
      disabled={disabled || loading}
      {...props}
    >
      {children}
      <Spinner
        className={style.ButtonSpinner}
        active={loading}
        inverted
        absolute
        strokeWidth={9}
      />
    </ButtonTag>
  );
};

export default Button;
