import React from "react";
import { Redirect, Route } from "react-router-dom";
import AuthService from "./services/auth.service";

import {
  DEFAULT_UNAUTHED_ROUTE,
  ROUTE_ADMIN,
  getDefaultAuthedRoute,
} from "./App";

const PrivateRoute = ({ component: Component, admin = false, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (AuthService.isAuthenticated()) {
        if (AuthService.isAdmin() === admin) {
          return <Component {...props} />;
        }
        if (AuthService.isAdmin()) {
          return <Redirect to={{ pathname: ROUTE_ADMIN }} />;
        }
        return <Redirect to={{ pathname: getDefaultAuthedRoute() }} />;
      }
      return (
        <Redirect
          to={{
            pathname: DEFAULT_UNAUTHED_ROUTE,
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);

export default PrivateRoute;
