import React, { useEffect } from "react";
import { Switch, Route, Router, generatePath, useLocation } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import History from "./history";

import AuthService from "./services/auth.service";
import { trackPage, trackUser } from "./services/analytics.service";

import Home from "./containers/Home";
import Auth from "./containers/Auth";
import QuizQuestion from "./containers/QuizQuestion";
import PosterWelcome from "./containers/PosterWelcome";
import Admin from "./containers/Admin";
import AdminClient from "./containers/AdminClient";
import ForgotPassword from "./containers/ForgotPassword";
import ResetPassword from "./containers/ResetPassword";
import Poster from "./containers/Poster";
import SharedProgress from "./containers/SharedProgress";

import { AppContextProvider } from "./contexts/AppContext";

import "./App.scss";
import { CommentsContextProvider } from "./contexts/CommentsContext";

export const ROUTE_HOME = "/";
export const ROUTE_AUTH = "/auth";
export const ROUTE_FORGOT_PASSWORD = "/forgot-password";
export const ROUTE_RESET_PASSWORD = "/reset-password/:token";
export const ROUTE_DAY_WELCOME = "/day/:dayId";
export const ROUTE_DAY_COMPLETED = "/day/:dayId/completed";
export const ROUTE_DAY_QUESTION = "/day/:dayId/:questionId";
export const ROUTE_ADMIN = "/admin";
export const ROUTE_ADMIN_CLIENT = "/admin/:clientId";
export const ROUTE_POSTER_WELCOME = "/destiny";
export const ROUTE_POSTER = "/destiny/:style";
export const ROUTE_POSTER_SHARED = "/destiny/:style/:hash";
export const ROUTE_PRESENT_VALUES_SHARED = "/present-values/:hash";
export const ROUTE_NEW_VALUES_SHARED = "/present-and-new-values/:hash";

export const DEFAULT_UNAUTHED_ROUTE = ROUTE_HOME;
export const getDefaultAuthedRoute = () => {
  return (
    AuthService.getLastLocation() ||
    generatePath(ROUTE_DAY_COMPLETED, { dayId: "1" })
  );
};

// useLocation can only be accessed by children of `<Router>` so a simple
// observer component is required to watch for route changes
function LocationObserver() {
  const { pathname } = useLocation();

  useEffect(() => trackPage(), [pathname]);

  return null;
}

function App() {
  useEffect(() => {
    if (AuthService.isAuthenticated()) trackUser();
  }, []);

  return (
    <Router history={History}>
      <Switch>
        <PrivateRoute admin path={ROUTE_ADMIN_CLIENT} component={AdminClient} />
        <PrivateRoute admin path={ROUTE_ADMIN} component={Admin} />
        <PublicRoute path={ROUTE_HOME} exact component={Home} />
        <PublicRoute path={ROUTE_AUTH} component={Auth} />
        <PublicRoute
          path={ROUTE_FORGOT_PASSWORD}
          component={ForgotPassword}
          exact
        />
        <PublicRoute path={ROUTE_RESET_PASSWORD} component={ResetPassword} />
        <PrivateRoute
          path={ROUTE_POSTER_WELCOME}
          component={PosterWelcome}
          exact
        />
        <PrivateRoute path={ROUTE_DAY_WELCOME} component={QuizQuestion} exact />
        <PrivateRoute path={ROUTE_DAY_COMPLETED} component={QuizQuestion} />
        <PrivateRoute
          path={ROUTE_DAY_QUESTION}
          component={QuizQuestion}
          exact
        />
        <Route
          path={ROUTE_PRESENT_VALUES_SHARED}
          component={SharedProgress}
        />
        <Route
          path={ROUTE_NEW_VALUES_SHARED}
          component={SharedProgress}
        />
        <PrivateRoute path={ROUTE_POSTER} component={Poster} exact />
        <Route path={ROUTE_POSTER_SHARED} component={Poster} />
      </Switch>
      <LocationObserver />
    </Router>
  );
}

const AppWithContexts = (props) => (
  <AppContextProvider>
    <CommentsContextProvider>
      <App {...props} />
    </CommentsContextProvider>
  </AppContextProvider>
);

export default AppWithContexts;
