import React, {
  useRef,
  Fragment,
  useEffect,
  useState,
  useMemo,
  useContext,
  useCallback,
  createRef,
} from "react";
import {
  Link,
  useHistory,
  useLocation,
  Redirect,
  generatePath,
  useRouteMatch,
} from "react-router-dom";
import cc from "classcat";
import { v4 as uuidv4 } from "uuid";

import AuthService from "../../services/auth.service";
import QuizService from "../../services/quiz.service";

import { days } from "../../days.json";
import {
  ROUTE_DAY_WELCOME,
  ROUTE_DAY_COMPLETED,
  ROUTE_DAY_QUESTION,
  ROUTE_POSTER_WELCOME,
} from "../../App";

import { fakeLoading } from "../../utils";
import { AppContext } from "../../contexts/AppContext";

import Page from "../../components/Page";
import LeftPanel from "../../components/LeftPanel";
import RightPanel from "../../components/RightPanel";
import Toolbar from "../Toolbar";
import Input from "../../components/Input";
import Button from "../../components/Button";
import ButtonWithPrompt from "../../components/ButtonWithPrompt";
import SortableInputs from "../../components/SortableInputs";

import style from "./index.module.scss";

export const FORBIDDEN_NAVIGATION_MESSAGE =
  "Please fix the errors before moving on other steps";

const STEP_WELCOME = "welcome";
const STEP_QUESTION = "question";
const STEP_COMPLETED = "completed";

const LOCAL_QUESTION = "currentQuestion";

const computeDayRoute = (pageType, dayId, questionId) => {
  const params = {
    dayId,
  };
  if (questionId) {
    params.questionId = questionId;
  }
  return generatePath(pageType, params);
};

const POSTER_SECTIONS = [
  "mission_statement",
  "towards",
  "ignore_center",
  "away",
  "power_virtues",
  "goals",
  "relationship_vision",
];

const MiniPoster = ({ active }) => (
  <div className={style.MiniPoster} aria-hidden="true">
    <span className={style.MiniPosterTitle}>My Destiny</span>
    <div className={style.MiniPosterGrid}>
      {POSTER_SECTIONS.map((section) => (
        <span
          key={section}
          className={cc(section === active && style.MiniPosterActiveCell)}
        />
      ))}
    </div>
  </div>
);

const QuizQuestion = ({ match }) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    AuthService.setLastLocation(location.pathname);
  }, [location.pathname]);

  const fieldRefs = useRef();

  const { currentDay, updateUserProgress } = useContext(AppContext);

  const { dayId, questionId } = match.params;

  const day = days[dayId - 1];

  const [loading, setLoading] = useState(false);
  const [touched, setTouched] = useState(false);
  const [answer, setAnswer] = useState({});
  const [answerDefault, setAnswerDefault] = useState({});
  const [answerError, setAnswerError] = useState({});
  const [returningUser, setReturningUser] = useState(false);
  const [fieldSteps, setFieldSteps] = useState({});

  // const routeMatchWelcome = useRouteMatch(ROUTE_DAY_WELCOME);
  const routeMatchQuestion = useRouteMatch(ROUTE_DAY_QUESTION);
  const routeMatchCompleted = useRouteMatch(ROUTE_DAY_COMPLETED);

  const step = useMemo(() => {
    if (routeMatchCompleted) return STEP_COMPLETED;
    else if (routeMatchQuestion) return STEP_QUESTION;
    return STEP_WELCOME;
  }, [routeMatchCompleted, routeMatchQuestion]);

  const { question, questionIndex, nextRoute, questionsLinks, progress } =
    useMemo(() => {
      let question = null;
      let nextRoute = null;
      let progress = 0;

      const questions = day?.questions || [];

      const questionsLength = questions.length;
      let questionIndex = 0;

      // Some days have facultative values, so we can't rely on values filled
      // to check if a question has been completed. Here we use the day progress
      const dayProgressPercentage = AuthService.getUserProgress()?.[dayId] || 0;
      const completedQuestions =
        dayProgressPercentage / (100 / questionsLength);
      const questionsLinks = [];
      for (let i = 0; i < questions.length; i++) {
        const question = questions[i];
        // Enabled links are only:
        // 1) the already completed ones
        // 2) the next one after the last completed one
        // 3) the one corresponding to the current page
        questionsLinks.push({
          route: computeDayRoute(ROUTE_DAY_QUESTION, dayId, question.id),
          enabled: i < completedQuestions + 1 || question.id === questionId,
        });
      }

      if (questionId) {
        questionIndex = questions.findIndex((q) => q.id === questionId);

        progress = questionsLength
          ? Math.round(((questionIndex + 1) / questionsLength) * 100)
          : 0;

        question = questions[questionIndex];
        const nextQuestion = questions[questionIndex + 1] || null;

        if (nextQuestion) {
          nextRoute = computeDayRoute(
            ROUTE_DAY_QUESTION,
            dayId,
            nextQuestion.id
          );
        } else {
          nextRoute = computeDayRoute(ROUTE_DAY_COMPLETED, dayId);
        }
      } else if (step === STEP_WELCOME && questions[0]) {
        nextRoute = computeDayRoute(ROUTE_DAY_QUESTION, dayId, questions[0].id);
      } else if (step === STEP_COMPLETED) {
        if (+dayId < currentDay) {
          nextRoute = computeDayRoute(ROUTE_DAY_WELCOME, +dayId + 1);
        }
      }

      return {
        progress,
        question,
        questionsLength,
        questionIndex,
        nextRoute,
        questionsLinks,
      };
    }, [step, questionId, day, currentDay, dayId]);

  const introRoute = useMemo(
    () => generatePath(ROUTE_DAY_WELCOME, { dayId }),
    [dayId]
  );

  const isValid = useMemo(() => {
    if (!routeMatchQuestion || !question) return null;

    let valid = true;

    // Rather than check every key in answerError, this loops through this
    // current question's fields and only checks to see if there are
    // errors for those as others are irrelevant – they are kept across browser
    // forward/back etc
    (question?.fields || []).forEach((field) => {
      if ((answerError || {})[field.id]) {
        valid = false;
      }
    });

    return valid;
  }, [routeMatchQuestion, answerError, question]);

  const allowNavigation = !touched || isValid;

  useEffect(() => {
    if (routeMatchQuestion) return;

    setAnswerError({});
  }, [routeMatchQuestion]);

  const storeAnswer = async () => {
    if (!isValid) {
      return false;
    }

    await QuizService.setAnswer(answer, dayId, progress);
    updateUserProgress();
    setAnswerDefault(answer);
    return true;
  };

  useEffect(() => {
    setTouched(false);
    setAnswerError({});
  }, [question]);

  useEffect(() => {
    if (!question) return;

    const newAnswer = {};
    const userAnswers = AuthService.getUser()?.answer;
    const temporaryAnswers =
      JSON.parse(localStorage.getItem(LOCAL_QUESTION)) || {};
    const newFieldRefs = {};
    let isReturning = true;

    (question?.fields || []).forEach((field) => {
      const fieldId = field.id;
      const fieldIsSortable = field.question_type === "sortable";
      const defaultAnswer = fieldIsSortable ? null : "";
      const userAnswer = userAnswers?.[fieldId];
      const temporaryAnswer = temporaryAnswers[fieldId];

      if ((fieldIsSortable && !(userAnswer || []).length) || !userAnswer) {
        isReturning = false;
      }

      newAnswer[fieldId] = userAnswer || temporaryAnswer || defaultAnswer;

      // Adds ids in to existing data that don't currently have it – legacy users. This might
      // be removable if QA agree to nuke their testing accounts.
      if (fieldIsSortable && newAnswer[fieldId]) {
        newAnswer[fieldId] = newAnswer[fieldId].map((v) => {
          if (!v.id || v.id.startsWith("id-")) {
            v.id = uuidv4();
          }
          return v;
        });
      }

      newFieldRefs[fieldId] = createRef();
    });

    setAnswer(newAnswer);
    setAnswerDefault(newAnswer);
    setReturningUser(isReturning);

    fieldRefs.current = newFieldRefs;

    setLoading(false);
  }, [question]);

  useEffect(() => {
    localStorage.setItem(LOCAL_QUESTION, JSON.stringify(answer));
  }, [answer]);

  const handleGoToNextQuestion = useCallback(async () => {
    setLoading(true);
    // Little time out creates a feeling of progress as it shows the loader
    await fakeLoading();
    history.push(nextRoute);
  }, [nextRoute, history]);

  const handleChange = useCallback((value, fieldId) => {
    setTouched(true);
    setAnswer((answer) => ({
      ...answer,
      [fieldId]: value,
    }));
  }, []);

  const handleError = useCallback((error, fieldId) => {
    setAnswerError((newError) => ({
      ...newError,
      [fieldId]: error,
    }));
  }, []);

  const handleFieldStepChange = (newFieldStep, fieldId) => {
    storeAnswer();
    setFieldSteps((oldFieldSteps) => ({
      ...oldFieldSteps,
      [fieldId]: newFieldStep,
    }));
  };

  const handleSubmitQuestion = async (e) => {
    setLoading(true);
    e.preventDefault();

    const success = await storeAnswer();

    if (success) {
      setAnswerError({});
      handleGoToNextQuestion();
    } else {
      setLoading(false);
    }
  };

  const sidebarContent = useMemo(() => {
    let titleFragment = null;
    let descriptionFragment = null;
    let quote;

    if (step === STEP_QUESTION && question) {
      let questionTitle = question?.title;
      let questionDescription = question?.description;

      const questionTitleDynamicFieldId = questionTitle?.field_id;
      const questionDescriptionDynamicFieldId = questionDescription?.field_id;

      if (questionTitleDynamicFieldId) {
        questionTitle =
          questionTitle[fieldSteps?.[questionTitleDynamicFieldId]];
      }

      if (questionDescriptionDynamicFieldId) {
        questionDescription =
          questionDescription[fieldSteps?.[questionDescriptionDynamicFieldId]];
      }

      titleFragment = <strong>{questionTitle}</strong>;

      descriptionFragment = (
        <Fragment>
          {(questionDescription || []).map((d, i) => (
            <p className={style.QuizQuestionSidebarDescription} key={i}>
              {d}
            </p>
          ))}
          {question?.list && (
            <ul className={style.QuizQuestionSidebarList}>
              {question.list.map((l, i) => (
                <li key={i}>{l}</li>
              ))}
            </ul>
          )}
        </Fragment>
      );
    }

    if (step !== STEP_QUESTION) {
      const stepContent = day && day[`${step}_screen`];
      const stepSubtitleDefault =
        step === STEP_WELCOME ? "Introduction" : "Completed";

      titleFragment = (
        <Fragment>
          <strong>{stepContent?.title || `Day ${dayId}`} </strong>
          <br />
          {stepContent?.sub_title || stepSubtitleDefault}
        </Fragment>
      );

      if (stepContent?.description) {
        descriptionFragment = <p>{stepContent.description}</p>;
      }

      quote = stepContent?.quote;
    }

    return {
      titleFragment,
      descriptionFragment,
      quote,
    };
  }, [question, step, day, dayId, fieldSteps]);

  if (!currentDay) return null;

  if (dayId === "1" && step !== STEP_COMPLETED) {
    return <Redirect to={computeDayRoute(ROUTE_DAY_COMPLETED, "1")} />;
  }

  if (dayId === "6") {
    return <Redirect to={ROUTE_POSTER_WELCOME} />;
  }

  if (+dayId > currentDay) {
    return <Redirect to={computeDayRoute(ROUTE_DAY_COMPLETED, currentDay)} />;
  }

  if (step !== STEP_QUESTION) {
    return (
      <Page day={+dayId} key={`${dayId}-${step}`}>
        <LeftPanel
          title={sidebarContent.titleFragment}
          description={sidebarContent.descriptionFragment}
          quote={sidebarContent.quote}
        >
          <Button
            to={nextRoute || undefined}
            className={style.QuizQuestionSidebarNextButton}
            disabled={!nextRoute}
          >
            {step === STEP_WELCOME && `Start Day ${dayId}`}
            {step === STEP_COMPLETED &&
              dayId === "5" &&
              "Go to Poster Creation"}
            {step === STEP_COMPLETED &&
              dayId !== "5" &&
              (nextRoute ? `Go to Day ${+dayId + 1}` : "Unlocks tomorrow")}
          </Button>
        </LeftPanel>
        <RightPanel className={style.QuizQuestionRightPanel}>
          <div className={style.QuizQuestionTopArea}>
            <Toolbar dayId={dayId} />
          </div>
        </RightPanel>
      </Page>
    );
  }

  const questionIsSortable =
    question.fields.length === 1 &&
    question.fields[0].question_type === "sortable";

  return (
    <Page day={+dayId}>
      <LeftPanel
        className={style.QuizQuestionSidebar}
        title={sidebarContent.titleFragment}
        description={sidebarContent.descriptionFragment}
        footer={
          <Fragment>
            <MiniPoster active={question?.mini_poster} />
          </Fragment>
        }
      />

      <RightPanel key={questionId} className={style.QuizQuestionRightPanel}>
        <div className={style.QuizQuestionTopArea}>
          <Toolbar dayId={dayId} />
          <div className={style.QuizQuestionsContainer}>
            <nav className={style.QuizQuestionsNav}>
              <Link
                to={allowNavigation ? introRoute : "#"}
                className={cc(!allowNavigation && style.forbidNavigation)}
                title={
                  allowNavigation ? undefined : FORBIDDEN_NAVIGATION_MESSAGE
                }
                onClick={storeAnswer}
              >
                Intro
              </Link>
              {questionsLinks.map(({ route, enabled }, i) => {
                const current = route === location.pathname;

                return (
                  <Link
                    key={route}
                    to={allowNavigation && enabled ? route : "#"}
                    aria-current={current ? "page" : undefined}
                    aria-disabled={!enabled}
                    tabIndex={enabled ? undefined : -1}
                    className={cc(
                      !current && !allowNavigation && style.forbidNavigation
                    )}
                    title={
                      allowNavigation ? undefined : FORBIDDEN_NAVIGATION_MESSAGE
                    }
                    onClick={
                      allowNavigation && enabled ? storeAnswer : undefined
                    }
                  >
                    Q{i + 1}
                  </Link>
                );
              })}
            </nav>
          </div>
        </div>
        <form onSubmit={handleSubmitQuestion}>
          <div
            className={cc([style.Fields, questionIsSortable && style.Sortable])}
          >
            {fieldRefs.current &&
              question?.fields &&
              question.fields.map((field, fieldIndex) => {
                const {
                  id: fieldId,
                  question_type: questionType = "text",
                  label,
                  helper,
                  required,
                  max: fieldMax,
                } = field;

                return (
                  <Fragment key={field.id}>
                    {questionType === "text" && (
                      <Fragment>
                        <Input
                          ref={fieldRefs.current[fieldId]}
                          id={fieldId}
                          key={fieldIndex}
                          label={label}
                          placeholder={field.placeholder}
                          required={required}
                          helper={helper}
                          min={field.min}
                          max={fieldMax}
                          defaultValue={answerDefault?.[fieldId]}
                          value={answer?.[fieldId] || ""}
                          onChange={(e) =>
                            handleChange(e.target.value, fieldId)
                          }
                          error={answerError?.[fieldId]}
                          onError={handleError}
                          autoFocus={fieldIndex === 0}
                          readonly={loading}
                        />
                      </Fragment>
                    )}

                    {questionType === "sortable" && (
                      <SortableInputs
                        fieldId={fieldId}
                        stepLabelPrefix={`Question ${questionIndex + 1}`}
                        repeatMin={field.min_repeat}
                        repeatMax={field.max_repeat}
                        primaryKey={field.primary_key}
                        primaryLabel={field.primary_label || label}
                        primaryMin={field.primary_min}
                        primaryMax={field.primary_max}
                        primaryPlaceholder={field.primary_placeholder}
                        primarySubmit={field.primary_submit}
                        primaryOrderingLabel={field.primary_ordering_label}
                        secondaryKey={field.secondary_key}
                        secondaryLabel={field.secondary_label}
                        secondaryMin={field.secondary_min}
                        secondaryMax={field.secondary_max}
                        secondaryPlaceholder={field.secondary_placeholder}
                        secondarySubmit={field.secondary_submit}
                        value={answer?.[fieldId]}
                        defaultValue={answerDefault?.[fieldId]}
                        onChange={(value) => handleChange(value, fieldId)}
                        onError={handleError}
                        onStepChange={handleFieldStepChange}
                        parentFormLoading={loading}
                        returningUser={returningUser}
                      />
                    )}
                  </Fragment>
                );
              })}

            {!(
              question.fields.length === 1 &&
              question.fields[0].question_type === "sortable"
            ) &&
              isValid && (
                <ButtonWithPrompt
                  loading={loading}
                  label="Save"
                  keyPrompt={
                    !question.fields.some(
                      (field) => field.question_type === "text_long"
                    )
                  }
                />
              )}
          </div>
        </form>

        {/*<pre><strong>Field steps</strong> {JSON.stringify(fieldSteps, null, 2)}</pre>*/}
        {/*<pre><strong>Initial Value</strong> {JSON.stringify(initialValue, null, 2)}</pre>*/}
        {/*<pre><strong>Answer</strong> {JSON.stringify(answer, null, 2)}</pre>*/}
        {/*<pre><strong>Errors</strong> {isValid ? '++' : '--'} {JSON.stringify(answerError, null, 2)}</pre>*/}
        {/*<pre><strong>Question type</strong> {questionType}</pre>*/}
        {/*<pre><strong>Question</strong> {JSON.stringify(question, null, 2)}</pre>*/}
      </RightPanel>
    </Page>
  );
};

export default QuizQuestion;
