import React, {
  useState,
  useMemo,
  createContext,
  useContext,
  useEffect,
} from "react";

import AuthService from "../services/auth.service";
import CommentsService from "../services/comments.service";

const CommentsContext = createContext();

export function CommentsContextProvider(props) {
  // TODO: create a React context to manage the authentication state
  const [authenticated, setAuthenticated] = useState(false);

  useState(() => {
    function updateAuthenticatedState() {
      setAuthenticated(AuthService.isAuthenticated());
    }

    updateAuthenticatedState();

    const interval = setInterval(updateAuthenticatedState, 1000);
    return () => clearInterval(interval);
  }, []);

  const [comments, setComments] = useState([]);

  useEffect(() => {
    if (!authenticated) {
      setComments([]);
      return;
    }

    async function refreshComments() {
      try {
        const comments = await CommentsService.getComments();
        setComments(comments);
      } catch (err) {
        console.error(err);
      }
    }

    // Load comments immediately when the context initialises and the user is
    // authenticated
    refreshComments();

    // Install listener for new messages
    const unsubscribeFunction = CommentsService.subscribeNewComments(
      AuthService.getUser().id,
      refreshComments
    );
    return () => unsubscribeFunction();
  }, [authenticated]);

  const unreadComments = useMemo(
    () => !!comments.find(({ read }) => read === false),
    [comments]
  );

  const setCommentRead = (commentId, read) => {
    const newComments = [...comments];
    const index = newComments.findIndex((comment) => comment.id === commentId);
    newComments[index] = { ...newComments[index], read };
    setComments(newComments);
  };

  const markAsRead = (commentId) => {
    // Optimistically mark the comment as read already...
    setCommentRead(commentId, true);
    CommentsService.markCommentAsRead(commentId)
      // ...but revert it if the API call fails
      .catch(() => setCommentRead(commentId, false));
  };

  return (
    <CommentsContext.Provider
      value={{ comments, unreadComments, markAsRead }}
      {...props}
    />
  );
}

export function useCommentsContext() {
  return useContext(CommentsContext);
}
