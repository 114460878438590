import history from "../history";
import api from "./api.service";
import { trackLogout, trackUser, trackEvent } from "./analytics.service";
import { DEFAULT_UNAUTHED_ROUTE } from "../App";
import { resetAdminCache } from "../containers/Admin";

export class ValidationError extends Error {}
export class InvalidUsernamePasswordError extends Error {}
export class UnauthorizedError extends Error {}
export class UnregisteredError extends Error {}
export class InvalidTokenError extends Error {}

const KEYS_PREFIX = "dwd";
export const LOCAL_USER = `${KEYS_PREFIX}_user`;
export const LOCAL_AUTH_TOKEN = `${KEYS_PREFIX}_auth_token`;
export const LOCAL_LOCATION = `${KEYS_PREFIX}_location`;

/**
 * Store just user info
 */
function updateUser(data) {
  localStorage.setItem(LOCAL_USER, JSON.stringify(data));
}

/**
 * Store user info and token in localStorage
 */
function setUser(data) {
  updateUser(data);
  localStorage.setItem(LOCAL_AUTH_TOKEN, data.auth_token);
}

/**
 * Retrieves the user info from localStorage
 */
function getUser() {
  return JSON.parse(localStorage.getItem(LOCAL_USER));
}

/**
 * Remove user info and token from localStorage
 */
function removeUser() {
  localStorage.removeItem(LOCAL_USER);
  localStorage.removeItem(LOCAL_AUTH_TOKEN);
}

/**
 * Check if User is authenticated
 */
function isAuthenticated() {
  if (getUser()) return true;
  return false;
}

/**
 * Check if User is an admin
 */
function isAdmin() {
  return getUser().is_client === false;
}

function isSuperUser() {
  return getUser().is_superuser === true;
}

function getUserGroup() {
  return getUser().group?.name;
}

/**
 * Store users last location
 */
function setLastLocation(pathname) {
  localStorage.setItem(LOCAL_LOCATION, pathname);
}

/**
 * Get users last location
 */
function getLastLocation() {
  return localStorage.getItem(JSON.stringify(LOCAL_LOCATION));
}

/**
 * Retrieves the user progress from localStorage
 */
function getUserProgress() {
  const user = JSON.parse(localStorage.getItem(LOCAL_USER));

  return user?.answer?.progress_details || {};
}

/**
 * Log out
 */
function logout() {
  removeUser();
  resetAdminCache();
  trackLogout();
  history.push(DEFAULT_UNAUTHED_ROUTE);
}

async function user() {
  let response;

  try {
    response = await api().get("/me");
  } catch (e) {
    const { errors } = e?.response?.data;

    if (errors && errors[0]) {
      throw new Error(errors[0].message);
    }

    throw new Error();
  }

  updateUser(response.data);

  return response.data;
}

/**
 * Log in
 * @param {object} body
 * @param {string} body.email
 * @param {string} body.password
 */
async function login({ email, password }) {
  let response;

  try {
    response = await api().post("/auth/login", { email, password });
  } catch ({ response }) {
    const { error_type: type, errors } = response?.data || {};

    if (errors && errors[0]) {
      const { message } = errors[0];

      trackEvent("loginFail", {
        email,
        message,
      });

      if (
        type === "WrongArguments" &&
        message.startsWith("You do not appear to be registered")
      ) {
        throw new UnregisteredError(message);
      } else if (
        type === "WrongArguments" &&
        message.startsWith("Invalid username")
      ) {
        throw new InvalidUsernamePasswordError(message);
      } else {
        throw new Error(message);
      }
    }

    trackEvent("loginFail", {
      email,
      message: "unknown-error",
    });

    throw new Error();
  }

  setUser(response.data);
  trackUser();
  trackEvent("loginSuccess", { email });

  return response.data;
}

/**
 * Register
 * @param {object} body
 * @param {string} body.email
 * @param {string} body.password
 * @param {string} body.firstName
 * @param {string} body.lastName
 */
async function register({ email, password, firstName, lastName }) {
  let response;

  try {
    const body = {
      email,
      password,
      first_name: firstName,
      last_name: lastName,
    };
    response = await api().post("/auth/register", body);
  } catch ({ response }) {
    const { error_type: type, errors } = response?.data || {};

    if (errors && errors[0]) {
      const { message } = errors[0];

      trackEvent("registerFail", {
        email,
        message,
      });

      if (
        type === "ValidationError" &&
        message.startsWith("Email is not authorized")
      ) {
        throw new UnauthorizedError(message);
      } else {
        throw new Error(message);
      }
    }

    trackEvent("registerFail", {
      email,
      message: "unknown-error",
    });

    throw new Error();
  }

  setUser(response.data);
  trackUser();
  trackEvent("registerSuccess", { email });

  return response.data;
}

/**
 * Reset Password
 * @param {string} email
 */
async function resetPassword(email) {
  let response;

  try {
    response = await api().post("/auth/password_reset", { email });
  } catch ({ response }) {
    const { errors } = response?.data || {};

    if (errors && errors[0]) {
      throw new Error(errors[0].message);
    }

    throw new Error();
  }

  return response.data;
}

/**
 * Reset Password
 * @param {string} token
 * @param {string} password
 */
async function confirmPassword(token, password) {
  let response;

  try {
    response = await api().post("/auth/password_reset_confirm", {
      token,
      new_password: password,
    });
  } catch ({ response }) {
    const { error_type: type, errors } = response?.data || {};

    if (errors && errors[0]) {
      const { message } = errors[0];

      if (
        type === "RequestValidationError" &&
        message.startsWith("Invalid token")
      ) {
        throw new InvalidTokenError(message);
      } else {
        throw new Error(message);
      }
    }

    throw new Error();
  }

  return response.data;
}

/**
 * Get User Status
 * @param {string} email
 */
async function getUserStatus(email) {
  let response;

  try {
    response = await api().get(
      `/users/status?email=${encodeURIComponent(email)}`
    );
  } catch ({ response }) {
    const { error_type: type, errors } = response?.data || {};

    if (errors && errors[0]) {
      const { message, field } = errors[0];

      trackEvent("userStatusFail", {
        email,
        message,
      });

      if (type === "ValidationError") {
        throw new ValidationError({ field, message });
      } else {
        throw new Error(message);
      }
    }

    trackEvent("userStatusFail", {
      email,
      message: "unknown-error",
    });

    throw new Error();
  }

  const { data } = response;

  // This returns 200 success for any valid email so an extra check must be made
  // to be sure that the user exists and is enabled for the active workshop.
  if (data?.is_registered_for_current_workshop) {
    trackEvent("userStatusSuccess", {
      email,
      passwordSet: data?.is_password_set,
    });
  } else {
    // This is likely not to happen, a response should contain data, but given that it
    // would have passed the error catch, this is just an extra check to be sure we're not
    // falsely reporting users not existing if for some reason no data is sent at all.
    const message =
      data?.is_registered_for_current_workshop === false
        ? "user-not-registered-for-event"
        : "unknown-error";

    trackEvent("userStatusFail", {
      email,
      message,
    });
  }

  return response.data;
}

const AuthService = {
  isAuthenticated,
  isAdmin,
  isSuperUser,
  user,
  login,
  logout,
  register,
  resetPassword,
  confirmPassword,
  getUserStatus,
  getUserGroup,
  updateUser,
  getUser,
  setLastLocation,
  getLastLocation,
  getUserProgress,
};

export default AuthService;
