import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from "react";
import {
  useHistory,
  useParams,
  Redirect,
  generatePath,
} from "react-router-dom";
import cc from "classcat";
import { saveAs } from "file-saver";

import { fakeLoading, normalizePosterData } from "../../utils";
import { trackEvent } from "../../services/analytics.service";
import AuthService from "../../services/auth.service";
import QuizService from "../../services/quiz.service";
import SharingService, {
  SHARING_CHECKPOINT_DAY_IDS,
} from "../../services/sharing.service";
import { POSTER_STYLE } from "./poster.config";
import { drawPoster } from "./poster.helpers";

import {
  ROUTE_HOME,
  ROUTE_POSTER_SHARED,
  ROUTE_POSTER_WELCOME,
  ROUTE_DAY_COMPLETED,
} from "../../App";
import { AppContext } from "../../contexts/AppContext";

import Button from "../../components/Button";
import Spinner from "../../components/Spinner";

import style from "./index.module.scss";

const POSTER_WIDTH = 1440 * 2;
const POSTER_HEIGHT = 960 * 2;

const POSTER_STYLE_THEME_DAYS = {
  [POSTER_STYLE.BLUE]: "1",
  [POSTER_STYLE.SIMPLY_SAGE]: "1",
  [POSTER_STYLE.GREEN]: "2",
  [POSTER_STYLE.WOODLAND_WAVE]: "2",
  [POSTER_STYLE.ORANGE]: "3",
  [POSTER_STYLE.BOLDLY_BRUSHED]: "3",
  [POSTER_STYLE.RED]: "4",
  [POSTER_STYLE.STRIKING_SURREAL]: "4",
  [POSTER_STYLE.PURPLE]: "5",
  [POSTER_STYLE.CREATIVITY_CLOUD]: "5",
};

const save = async (svgContainer, nameParts) => {
  // 1) Convert the already generated poster SVG in a blob
  const svg = svgContainer.querySelector("svg");
  var svgBlob = new Blob([svg.outerHTML], {
    type: "image/svg+xml;charset=utf-8",
  });
  // 2) Convert the blob in a data url
  var svgDataUrl = URL.createObjectURL(svgBlob);
  // 3) Load the data url in an image element, that can be drawn on a canvas
  await new Promise((resolve) => {
    var img = new Image();
    img.onload = async () => {
      // 4) The SVG poster has been loaded as image, draw it on a canvas
      const canvas = document.createElement("canvas");
      canvas.width = POSTER_WIDTH;
      canvas.height = POSTER_HEIGHT;
      const ctx = canvas.getContext("2d");

      // We draw the image 2 times because of a known bug in Safari, that
      // sometimes fails drawing the first one properly. Sometimes at the first
      // draw the background image is missing, but at the second attempt it's
      // always present. It can't be related to race conditions in our code
      // because we print an element already existent in the DOM, and the
      // background images are embedded as data-urls, so there's no external
      // resource to fetch. See this link for more info on this bug:
      // https://github.com/exupero/saveSvgAsPng/issues/223
      ctx.drawImage(img, 0, 0);
      await fakeLoading();
      ctx.drawImage(img, 0, 0);

      // 5) Convert the canvas in a PNG blob and save it as a file
      canvas.toBlob((blob) => {
        const prefix = nameParts
          ? `${nameParts.join(" ").replace(/\W/g, "_")}`
          : "My";
        saveAs(blob, `${prefix}_Date_with_Destiny_${Date.now()}.png`);
        URL.revokeObjectURL(svgDataUrl);
        resolve();
      });
    };
    img.src = svgDataUrl;
  });
};

const Poster = () => {
  const history = useHistory();
  const { style: posterStyle = POSTER_STYLE.BLUE, hash: sharedPosterHash } =
    useParams();
  const [mustHideLoader, setMustHideLoader] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isSharing, setIsSharing] = useState(false);

  const { currentDay, updateUserProgress } = useContext(AppContext);
  const placeholderRef = useRef(null);

  const [sourceData, setSourceData] = useState(null);
  const [hasCopiedSharedLinkToClipboard, setHasCopiedSharedLinkToClipboard] =
    useState(false);

  const isSharedPoster = !!sharedPosterHash;

  // useMemo justified here as getUser() calls JSON.parse()
  const user = useMemo(() => AuthService.getUser(), []);

  const isCopyShareButtonVisible =
    user && isSharedPoster && sourceData?.id === user.id;
  const isBackButtonVisible =
    user && (!isSharedPoster || sourceData?.id === user.id);

  const PAGE_URL = window.location.href;

  useEffect(() => {
    if (isSharedPoster) {
      const loadSharedAnswer = async () => {
        const sharedData = await SharingService.getSharedProgress(
          sharedPosterHash
        );
        // id is only provided on the shared "destiny" checkpoint, not "third_day" or "fifth_day"
        if (!sharedData?.id) {
          history.push(ROUTE_HOME);
          return;
        }
        setSourceData(sharedData);
      };
      loadSharedAnswer();
    } else {
      setSourceData(normalizePosterData(user));
    }
  }, [history, isSharedPoster, sharedPosterHash, user]);

  useEffect(() => {
    if (!isSharedPoster && currentDay !== 6) {
      return;
    }

    if (!sourceData) {
      return;
    }

    const doDraw = async () => {
      // drawPoster() blocks the main thread of the browser, so if called too
      // soon it prevents the page to be rendered. This is why we introduce a
      // small delay before calling it.
      // TODO: make drawPoster more efficient so it doesn't freeze the browser
      await fakeLoading();
      await drawPoster(sourceData, posterStyle, placeholderRef.current);
      setMustHideLoader(true);
    };

    doDraw();
  }, [currentDay, isSharedPoster, posterStyle, sourceData]);

  useEffect(() => {
    if (!hasCopiedSharedLinkToClipboard) {
      return;
    }
    const timeout = setTimeout(() => {
      setHasCopiedSharedLinkToClipboard(false);
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [hasCopiedSharedLinkToClipboard]);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(PAGE_URL);
    setHasCopiedSharedLinkToClipboard(true);
  };

  const handleSave = useCallback(async () => {
    setSaving(true);

    const nameParts = isSharedPoster
      ? [sourceData.first_name, sourceData.last_name]
      : undefined;
    await save(placeholderRef.current, nameParts);

    if (!isSharedPoster) {
      // This is async, but doesn't actually get reflected in the UI at all so doesn't need
      // to be awaited. This empty object sets progress for day 6 (the poster) to be 100
      // so that we mark the poster as complete in the sidebar

      updateUserProgress();

      trackEvent("userSavedPoster", {
        style: posterStyle,
      });

      await QuizService.setAnswer({}, "6", 100);
    }

    setSaving(false);
  }, [isSharedPoster, posterStyle, sourceData, updateUserProgress]);

  const handleShare = useCallback(async () => {
    setIsSharing(true);
    const share = async () => {
      const { hash } = await SharingService.shareProgress(
        SHARING_CHECKPOINT_DAY_IDS.DESTINY
      );
      setIsSharing(false);
      history.push(
        generatePath(ROUTE_POSTER_SHARED, { hash, style: posterStyle })
      );
    };
    share();
  }, [history, posterStyle]);

  if (!isSharedPoster && !currentDay) {
    return <Redirect to={generatePath(ROUTE_HOME)} />;
  }

  if (!isSharedPoster && currentDay < 6) {
    return (
      <Redirect to={generatePath(ROUTE_DAY_COMPLETED, { dayId: currentDay })} />
    );
  }

  return (
    <div
      className={cc([
        style.PosterPage,
        style[`PosterPage${POSTER_STYLE_THEME_DAYS[posterStyle]}`],
      ])}
    >
      {!mustHideLoader && (
        <div className={style.PosterLoader}>
          <Spinner absolute active />
        </div>
      )}
      <header className={style.PosterHeader}>
        {isBackButtonVisible && <Button to={ROUTE_POSTER_WELCOME}>Back</Button>}

        <div className={style.CopySharedLinkButtonContainer}>
          <h3 className={style.PosterTitle}>Date with Destiny Poster</h3>
          {isCopyShareButtonVisible && (
            <div className={style.CopySharedLinkButton}>
              <a href={PAGE_URL} className={style.SharedPosterLink}>
                {PAGE_URL}
              </a>
              <Button
                disabled={hasCopiedSharedLinkToClipboard}
                onClick={handleCopyToClipboard}
              >
                {hasCopiedSharedLinkToClipboard ? "Copied" : "Copy"} link to
                clipboard
              </Button>
            </div>
          )}
        </div>
        <div>
          {!isSharedPoster && (
            <Button
              onClick={handleShare}
              loading={isSharing}
              className={style.ShareButton}
            >
              Share
            </Button>
          )}
          <Button onClick={handleSave} loading={saving}>
            Save
          </Button>
        </div>
      </header>
      <section ref={placeholderRef} className={style.PosterContainer}></section>
    </div>
  );
};

export default Poster;
