import React, { useEffect, useMemo, useState } from "react";
import cc from "classcat";

import { days } from "../../days.json";

import CommentsService from "../../services/comments.service";

import { ReactComponent as CloseIcon } from "../../images/icons/close.svg";
import { ReactComponent as ChevronIcon } from "../../images/icons/chevron.svg";
import { ReactComponent as CheckIcon } from "../../images/icons/check.svg";
import { ReactComponent as DotIcon } from "../../images/icons/dot.svg";

import style from "./CommentsPanel.module.scss";

const Day = ({ clientId, day, refreshComments }) => {
  const [expanded, setExpanded] = useState(true);
  const buttonLabel = expanded ? "Collapse" : "Expand";

  return (
    <section className={style.Day}>
      <header onClick={() => setExpanded(!expanded)}>
        <h3>Day {day.number}</h3>
        {/* Even if the entire header is clickable we put a button inside for
            a more accessible and semantic markup */}
        <button
          className={cc([
            style.IconOnlyButton,
            style.ExpandCollapse,
            expanded && style.Expanded,
          ])}
          aria-label={buttonLabel}
          title={buttonLabel}
        >
          <ChevronIcon role="presentation" />
        </button>
      </header>
      <div hidden={!expanded}>
        {day.questions.map((question) => (
          <Question
            key={question.fieldId}
            clientId={clientId}
            question={question}
            refreshComments={refreshComments}
          />
        ))}
      </div>
    </section>
  );
};

const Question = ({ clientId, question, refreshComments }) => {
  const [adding, setAdding] = useState(false);

  const authorFieldName = "author";
  const textFieldName = "text";

  const handleDeleteComment = (comment) => {
    CommentsService.deleteComment(comment.id)
      .catch((e) => alert(e))
      .finally(() => refreshComments());
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;

    CommentsService.addComment(
      clientId,
      question.fieldId,
      form[authorFieldName].value,
      form[textFieldName].value
    )
      .then(() => {
        setAdding(false);
        refreshComments();
      })
      .catch((e) => alert(e));
  };

  return (
    <section className={style.Question}>
      <h4>{question.shortTitle}</h4>
      <div className={style.Comments}>
        {question.comments.length === 0 ? (
          <div className={style.Empty}>No comments for this question.</div>
        ) : (
          question.comments.map((comment) => (
            <article key={comment.id} className={style.Comment}>
              <div className={style.ReadState}>
                {comment.read ? (
                  <CheckIcon
                    role="presentation"
                    title="Message has been read by the user"
                  />
                ) : (
                  <DotIcon
                    role="presentation"
                    title="Message not yet read by the user"
                  />
                )}
              </div>
              {comment.author && (
                <address className={style.Author}>{comment.author}</address>
              )}
              <p className={style.Text}>{comment.text}</p>

              <div className={style.CommentActions}>
                <button
                  type="button"
                  aria-label="Delete comment"
                  onClick={() => handleDeleteComment(comment)}
                >
                  Delete
                </button>
              </div>
            </article>
          ))
        )}
      </div>
      {adding ? (
        <form className={style.AddCommentForm} onSubmit={handleSubmit}>
          <input
            name={authorFieldName}
            placeholder="Your name"
            type="text"
            maxLength={64}
            autoFocus
          />
          <textarea
            name={textFieldName}
            placeholder="Write your comment here"
            maxLength={250}
            rows="4"
            required
          />
          <div className={style.Buttons}>
            <button type="submit">Send Comment</button>
            <button type="button" onClick={() => setAdding(false)}>
              Cancel
            </button>
          </div>
        </form>
      ) : (
        <button className={style.AddComment} onClick={() => setAdding(true)}>
          Add Comment
        </button>
      )}
    </section>
  );
};

const CommentsPanel = ({ clientId, visible, onClose }) => {
  const [comments, setComments] = useState([]);
  const [refreshCommentsTrigger, setRefreshCommentsTrigger] = useState({});

  useEffect(() => {
    CommentsService.getComments(clientId).then(setComments);
  }, [clientId, refreshCommentsTrigger]);

  // This function just changes the value of refreshCommentsTrigger, so doing
  // telling React to run again the effect above
  const refreshComments = () => setRefreshCommentsTrigger({});

  const data = useMemo(() => {
    const commentsByFieldId = {};
    for (const comment of comments) {
      if (!commentsByFieldId[comment.fieldId]) {
        commentsByFieldId[comment.fieldId] = [];
      }
      commentsByFieldId[comment.fieldId].push(comment);
    }

    const data = [];
    for (let i = 0; i < days.length; i++) {
      const day = days[i];
      if (!day.questions) continue;
      const questions = day.questions.map((question) => ({
        shortTitle: question.short_title,
        // Questions always have only one field
        fieldId: question.fields[0].id,
        // API returns newest comments on top, we want them at the bottom in
        // this UI, so we reverse the array
        comments: commentsByFieldId[question.fields[0].id]?.reverse() || [],
      }));
      data.push({ number: i + 1, questions });
    }
    return data;
  }, [comments]);

  return (
    <section className={style.Panel} hidden={!visible}>
      <header>
        <h2>Comments</h2>
        <p>Expand to see all the comments.</p>
        <button
          className={cc([style.IconOnlyButton, style.Close])}
          onClick={onClose}
          aria-label="Close"
          title="Close"
        >
          <CloseIcon role="presentation" />
        </button>
      </header>
      {data.map((day) => (
        <Day
          key={day.number}
          clientId={clientId}
          day={day}
          refreshComments={refreshComments}
        />
      ))}
    </section>
  );
};

export default CommentsPanel;
