/* global analytics */

import AuthService from "./auth.service";

function analyticsEnabled() {
  if (window.analytics) return true;
  // CONTEXT env var is set by Netlify
  // eslint-disable-next-line no-process-env
  if (process.env.CONTEXT !== "production") {
    console.error("Analytics script not loaded properly");
  }
  return false;
}

export function trackPage() {
  if (!analyticsEnabled()) return;
  analytics.page();
}

export function trackUser() {
  if (!analyticsEnabled()) return;
  const {
    id,
    first_name: firstName,
    last_name: lastName,
    email,
  } = AuthService.getUser();

  analytics.identify(id, {
    firstName,
    lastName,
    email,
  });
}

export function trackEvent(eventName, properties = {}) {
  if (!analyticsEnabled()) return;
  analytics.track(eventName, properties);
}

export function trackLogout() {
  if (!analyticsEnabled()) return;
  analytics.reset();
}
