import React, {
  useState,
  createContext,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from "react";

import AppService from "../services/app.service";
import AuthService from "../services/auth.service";

const MILLISECONDS_IN_MINUTE = 1000 * 60;
const METADATA_POLL_INTERVAL = 60 * MILLISECONDS_IN_MINUTE;

export const AppContext = createContext();

export function AppContextProvider({ children }) {
  const [metadata, setMetadata] = useState(null);
  const [userProgress, setUserProgress] = useState({});

  const updateMetadata = useCallback(async () => {
    let newMetadata = null;

    if (AuthService.isAuthenticated()) {
      newMetadata = await AppService.getMetadata();
    }

    setMetadata(newMetadata);
  }, []);

  const updateUserProgress = useCallback(() => {
    const newUserProgress = AuthService.getUserProgress();
    setUserProgress(newUserProgress);
  }, []);

  useEffect(() => {
    updateMetadata();

    const pollMetadata = setInterval(updateMetadata, METADATA_POLL_INTERVAL);

    return () => clearInterval(pollMetadata);
  }, [updateMetadata]);

  useEffect(() => {
    updateUserProgress();
  }, [updateUserProgress]);

  const logout = useCallback(() => {
    AuthService.logout();
    updateMetadata();
    updateUserProgress();
  }, [updateMetadata, updateUserProgress]);

  const value = useMemo(() => {
    const activeDays = metadata?.active_days;
    let currentDay = null;
    let hasHitInactiveDay = false;

    (metadata?.active_days || []).forEach((day, index) => {
      if (hasHitInactiveDay) return;

      if (day.is_active) {
        currentDay = index + 1;
      } else {
        hasHitInactiveDay = true;
      }
    });

    return {
      metadata,
      updateMetadata,
      currentDay,
      activeDays,
      userProgress,
      updateUserProgress,
      logout,
    };
  }, [metadata, updateMetadata, userProgress, updateUserProgress, logout]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export function useAppContext() {
  return useContext(AppContext);
}
