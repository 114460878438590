import Ably from "ably";

import api from "./api.service";

/**
 * Subscribes to new comments added to a specific user.
 * @param {String} userId
 * @param {Function} callback Function to be called when a new comment is added.
 * @returns {Function} A function to unsubscribe from the comments releasing
 *                     all the resources.
 */
function subscribeNewComments(userId, callback) {
  const ably = new Ably.Realtime(process.env.REACT_APP_ABLY_SUBSCRIBE_ONLY_KEY);
  ably.channels
    .get(userId) // There is an Ably channel for each user, named with their id
    .subscribe("new_comment", callback);

  const unsubscribeFunction = () => ably.connection.close();
  return unsubscribeFunction;
}

/**
 * Returns all the comments of a user, newest on top.
 * @param {String|undefined} userId When authenticated as user it defaults to
 *                                  the user's id if not specified
 * @returns {Promise<[]>}
 */
async function getComments(userId) {
  const response = await api().get(`/comments`, {
    params: { client_id: userId },
  });

  const rawComments = response.data.results;
  if (!rawComments) {
    throw new Error(
      "Error retrieving comments from the API: no results in the response"
    );
  }

  const comments = rawComments.map((comment) => ({
    id: comment.id,
    fieldId: comment.section_name,
    author: comment.author_name,
    text: comment.text,
    read: !!comment.read_at,
  }));

  return comments;
}

/**
 * Add a new comment.
 * @param {String} userId
 * @param {String} fieldId fieldId of the question the comment relates to
 * @param {String} author
 * @param {String} text
 * @returns {Promise}
 */
async function addComment(userId, fieldId, author, text) {
  return api().post(`/comments`, {
    receiver: userId,
    section_name: fieldId,
    author_name: author,
    text,
  });
}

/**
 * Mark a comment as read
 * @param {String} commentId
 * @returns {Promise}
 */
function markCommentAsRead(commentId) {
  return api().post(`/comments/mark-as-read`, { comment_ids: [commentId] });
}

/**
 * Delete a comment
 * @param {*} commentId ID of the comment to delete.
 * @returns
 */
async function deleteComment(commentId) {
  return api().delete(`/comments/${commentId}`);
}

const CommentsService = {
  subscribeNewComments,
  getComments,
  addComment,
  markCommentAsRead,
  deleteComment,
};

export default CommentsService;
