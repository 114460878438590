import React, { useMemo } from "react";
import { useRouteMatch, Link, generatePath } from "react-router-dom";
import cc from "classcat";

import AuthService from "../../services/auth.service";

import {
  ROUTE_DAY_WELCOME,
  ROUTE_DAY_COMPLETED,
  ROUTE_HOME,
  ROUTE_POSTER_WELCOME,
} from "../../App";
import { useAppContext } from "../../contexts/AppContext";
import ProgressCircle from "../ProgressCircle";

import { ReactComponent as ProgressCompletedIcon } from "../../images/icons/progress-completed.svg";
import { ReactComponent as ProgressLockIcon } from "../../images/icons/progress-lock.svg";
import { ReactComponent as Logo } from "../../images/logo.svg";

import style from "./index.module.scss";

const ProgressSidebar = ({ day }) => {
  const { activeDays, currentDay, userProgress, logout } = useAppContext();

  const days = useMemo(() => {
    if (!activeDays) return null;

    const newDays = activeDays.map((day, index) => {
      const id = `${index + 1}`;
      const title = index === activeDays.length - 1 ? "Destiny" : `Day ${id}`;

      // Day one has no welcome state or questions once a user has signed up so the
      // completed state is shown for these users returning to this day.
      const linkPathname = id === "1" ? ROUTE_DAY_COMPLETED : ROUTE_DAY_WELCOME;

      const link =
        id === "6"
          ? ROUTE_POSTER_WELCOME
          : generatePath(linkPathname, { dayId: id });

      let dayProgress = 0;

      if (AuthService.isAuthenticated()) {
        if (index === 0) {
          dayProgress = 100;
        } else {
          dayProgress = userProgress[index + 1] || 0;
        }
      }

      return {
        id,
        title,
        link,
        // Day 1 should never be locked for logged in users – it is the auth flow, so if they
        // are able to log in, they are able to see it
        locked: index > 0 && index >= currentDay,
        dayProgress,
      };
    });

    return newDays;
  }, [activeDays, currentDay, userProgress]);

  const isHomeRoute = useRouteMatch(ROUTE_HOME);
  const isDayRoute = useRouteMatch(ROUTE_DAY_WELCOME);
  const isPosterRoute = useRouteMatch(ROUTE_POSTER_WELCOME);

  const showDays = !!days && !isHomeRoute.isExact;
  const { dayId: activeDayId } = isDayRoute?.params || {};

  return (
    <nav
      className={cc([
        style.Sidebar,
        isHomeRoute.isExact
          ? style.HomeSidebar
          : [style.ProgressSidebar, style[`ProgressSidebarDay${day}`]],
      ])}
    >
      <div className={style.Scrollable}>
        <Link className={style.ProgressSidebarLogo} to={ROUTE_HOME}>
          <Logo alt="Date With Destiny" />
        </Link>
        {showDays && (
          <>
            <ul className={style.ProgressSidebarMenu}>
              {days.map(({ id, title, locked, link, dayProgress }) => (
                <li className={style.ProgressSidebarItem} key={id}>
                  {locked ? (
                    <span className={style.ProgressSidebarFuture}>
                      <ProgressLockIcon role="presentation" />
                      <span>{title}</span>
                    </span>
                  ) : (
                    <Link
                      to={link}
                      className={cc([
                        style.ProgressSidebarLink,
                        (activeDayId === id || (id === "6" && isPosterRoute)) &&
                          style.ProgressSidebarLinkActive,
                      ])}
                    >
                      {dayProgress === 100 && (
                        <ProgressCompletedIcon role="presentation" />
                      )}
                      {dayProgress !== 100 && (
                        <ProgressCircle
                          size={18}
                          strokeSize={3}
                          perc={+dayProgress}
                        />
                      )}
                      <span>{title}</span>
                    </Link>
                  )}
                </li>
              ))}
            </ul>
            {AuthService.isAuthenticated() && (
              <button onClick={logout} className={style.ProgressSidebarLogout}>
                Logout
              </button>
            )}
          </>
        )}
      </div>
    </nav>
  );
};

export default ProgressSidebar;
