import { useEffect } from "react";

export default function useOnClickOutside(handler, ref1, ref2) {
  useEffect(() => {
    function handlePointerDown(event) {
      const pointerInsideRef = [ref1, ref2].some((ref) =>
        ref?.current?.contains(event.target)
      );
      if (pointerInsideRef) return;
      else handler(event);
    }

    document.addEventListener("pointerdown", handlePointerDown);
    return () => {
      document.removeEventListener("pointerdown", handlePointerDown);
    };
  }, [ref1, ref2, handler]);
}
