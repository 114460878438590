import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import cc from "classcat";

import AuthService from "../../services/auth.service";
import SharingService, {
  SHARED_CHECKPOINT_OPTIONS,
  SHARED_PROPERTY_NAMES,
} from "../../services/sharing.service";
import { ROUTE_HOME } from "../../App";
import { sorter } from "../../utils/common";

import Button from "../../components/Button";
import Spinner from "../../components/Spinner";

import { ReactComponent as Logo } from "../../images/logo.svg";

import style from "./index.module.scss";

const Values = ({ answer, title, sourcePropertyName }) => {
  return (
    <section className={style.Values}>
      <h3>{title}</h3>
      <ul>
        {(answer[sourcePropertyName] || []).sort(sorter).map((valueRule) => (
          <li key={valueRule.value}>
            <h4>{valueRule.value}</h4>
            <p>{valueRule.rule}</p>
          </li>
        ))}
      </ul>
    </section>
  );
};

const INITIAL_ANSWER = {};
const PAGE_TITLES = {
  [SHARED_CHECKPOINT_OPTIONS.THIRD_DAY]: "Day 3 Values",
  [SHARED_CHECKPOINT_OPTIONS.FIFTH_DAY]: "Day 5 Values",
  [SHARED_CHECKPOINT_OPTIONS.DESTINY]: "Destiny",
};

const SharedProgress = () => {
  const { hash } = useParams();
  const history = useHistory();

  const PAGE_URL = useMemo(() => window.location.href, []); // otherwise might save prev page URL

  const [mustHideLoader, setMustHideLoader] = useState(false);
  const [hasCopiedToClipboard, setHasCopiedToClipboard] = useState(false);

  const [answer, setAnswer] = useState(INITIAL_ANSWER);
  const [sharedCheckpoint, setSharedCheckpoint] = useState(null);

  const isAuthenticated = useMemo(() => AuthService.isAuthenticated(), []);

  // one or all parts of the name might not be present
  const fullName = useMemo(
    () =>
      answer
        ? [answer.first_name, answer.last_name]
            .filter((part) => !!part)
            .join(" ")
        : "",
    [answer]
  );
  const fullNameFormatted = useMemo(
    () => (fullName ? `${fullName}'s ` : ""),
    [fullName]
  );

  const pageTitle = useMemo(
    () => PAGE_TITLES[sharedCheckpoint] || "Loading",
    [sharedCheckpoint]
  );

  useEffect(() => {
    if (!hasCopiedToClipboard) {
      return;
    }
    const timeout = setTimeout(() => {
      setHasCopiedToClipboard(false);
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [hasCopiedToClipboard]);

  useEffect(() => {
    let timeout;
    if (answer !== INITIAL_ANSWER) {
      timeout = setTimeout(() => setMustHideLoader(true), 200);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [answer]);

  useEffect(() => {
    const loadSharedAnswer = async () => {
      const answer = await SharingService.getSharedProgress(hash);
      if (!answer) {
        history.push(ROUTE_HOME);
        return;
      }
      setAnswer(answer);
      if (answer.id) {
        setSharedCheckpoint(SHARED_CHECKPOINT_OPTIONS.DESTINY);
      } else if (answer[SHARED_PROPERTY_NAMES.NEW_AWAY]) {
        setSharedCheckpoint(SHARED_CHECKPOINT_OPTIONS.FIFTH_DAY);
      } else {
        setSharedCheckpoint(SHARED_CHECKPOINT_OPTIONS.THIRD_DAY);
      }
    };
    loadSharedAnswer();
  }, [hash, history]);

  const handleBackButtonClick = useCallback(() => history.goBack(), [history]);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(PAGE_URL);
    setHasCopiedToClipboard(true);
  };

  return (
    <div
      className={cc([
        style.SharedProgressPage,
        style[`SharedProgressPage${sharedCheckpoint}`],
      ])}
    >
      {!mustHideLoader && (
        <div className={style.Spinner}>
          <Spinner absolute active />
        </div>
      )}
      <header className={style.PosterHeader}>
        {isAuthenticated && (
          <Button className={style.BackButton} onClick={handleBackButtonClick}>
            Back
          </Button>
        )}
        <h3 className={style.WorksheetTitle}>{pageTitle}</h3>
      </header>
      <div className={style.WorksheetContainer}>
        <div className={style.WorksheetContainerOffset}>
          {sharedCheckpoint === SHARED_CHECKPOINT_OPTIONS.FIFTH_DAY && (
            <section>
              <h2>{fullNameFormatted}New Values</h2>
              <div className={style.ValuesContainer}>
                <Values
                  key="towards"
                  title="Towards Values"
                  sourcePropertyName={SHARED_PROPERTY_NAMES.NEW_TOWARDS}
                  answer={answer}
                />
                <Values
                  key="away"
                  title="Away Values"
                  sourcePropertyName={SHARED_PROPERTY_NAMES.NEW_AWAY}
                  answer={answer}
                />
              </div>
              <Logo role="presentation" className={style.Logo} />
            </section>
          )}
          <section>
            <h2>{fullNameFormatted}Present Values</h2>
            <div className={style.ValuesContainer}>
              <Values
                key="towards"
                title="Towards Values"
                sourcePropertyName={SHARED_PROPERTY_NAMES.OLD_TOWARDS}
                answer={answer}
              />
              <Values
                key="away"
                title="Away Values"
                sourcePropertyName={SHARED_PROPERTY_NAMES.OLD_AWAY}
                answer={answer}
              />
            </div>
            {sharedCheckpoint === SHARED_CHECKPOINT_OPTIONS.THIRD_DAY && (
              <Logo role="presentation" className={style.Logo} />
            )}
          </section>
          {isAuthenticated && (
            <div className={style.CopyLinkContainer}>
              <a href={PAGE_URL}>{PAGE_URL}</a>
              <Button
                disabled={hasCopiedToClipboard}
                className={style.CopyButton}
                onClick={handleCopyToClipboard}
              >
                {hasCopiedToClipboard ? "Copied" : "Copy"} to clipboard
              </Button>
            </div>
          )}
        </div>
        <div className={style.WorksheetContainerBackgroundPattern} />
      </div>
    </div>
  );
};

export default SharedProgress;
