import React, { useEffect, useState } from "react";
import cc from "classcat";

import { ReactComponent as SpinnerSvg } from "./spinner.svg";

import style from "./index.module.scss";

const Button = ({
  active,
  absolute,
  inverted,
  strokeWidth = 6,
  className,
  ...props
}) => {
  const [spinning, setSpinning] = useState(false);

  // Prevents the animation from running when the item isn't visible by
  // removing the element from the DOM using CSS
  useEffect(() => {
    if (active) {
      setSpinning(true);
    } else {
      setTimeout(() => {
        setSpinning(false);
      }, +style.transitionDurationFast);
    }
  }, [active]);

  return (
    <span
      aria-label={active ? "Loading" : undefined}
      aria-hidden={!active || undefined}
      {...props}
      className={cc([
        className,
        style.Spinner,
        {
          [style.SpinnerActive]: active,
          [style.SpinnerAbsolute]: absolute,
          [style.SpinnerInverted]: inverted,
        },
      ])}
      style={{
        "--stroke-width": strokeWidth,
      }}
    >
      {spinning && <SpinnerSvg role="presentation" />}
    </span>
  );
};

export default Button;
