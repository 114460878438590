import { useRef, useEffect } from "react";

export default function useFirstRenderEffect(fn) {
  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (!firstRenderRef.current) return;
    firstRenderRef.current = false;
    fn();
  });
}
