import React from "react";
import cc from "classcat";

import style from "./index.module.scss";

const RightPanel = ({ className, children, single, ...props }) => (
  <div
    {...props}
    className={cc([
      className,
      style.RightPanel,
      {
        [style.RightPanelSingle]: single,
      },
    ])}
  >
    {children}
  </div>
);

export default RightPanel;
