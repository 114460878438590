import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import AuthService, { InvalidTokenError } from "../../services/auth.service";

import { days } from "../../days.json";

import {
  getDefaultAuthedRoute,
  ROUTE_AUTH,
  ROUTE_FORGOT_PASSWORD,
} from "../../App";

import Page from "../../components/Page";
import LeftPanel from "../../components/LeftPanel";
import RightPanel from "../../components/RightPanel";
import Input from "../../components/Input";
import ErrorMessage from "../../components/ErrorMessage";
import ButtonWithPrompt from "../../components/ButtonWithPrompt";
import Button from "../../components/Button";

import style from "./index.module.scss";

const STEP_PASSWORD = "password";
const STEP_SUCCESS = "success";

const GENERIC_ERROR = "Something went wrong. Please try again.";

const DAY_ONE_CONTENT = days[0].welcome_screen;

const Auth = ({ match }) => {
  const { token } = match.params;

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(STEP_PASSWORD);

  const [globalError, setGlobalError] = useState(null);
  const [fieldError, setFieldError] = useState({});

  const [password, setPassword] = useState("");

  // Reset the form error when any input is typed in
  useEffect(() => {
    setGlobalError(null);
  }, [password]);

  const handleError = useCallback((error) => {
    setFieldError(error);
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (fieldError) return;

      setLoading(true);
      setGlobalError(null);

      try {
        await AuthService.confirmPassword(token, password);
      } catch (e) {
        let newGlobalError = GENERIC_ERROR;

        if (e instanceof InvalidTokenError) {
          newGlobalError = (
            <Fragment>
              Invalid reset URL. Please{" "}
              <Link to={ROUTE_FORGOT_PASSWORD}>generate a new one</Link>.
            </Fragment>
          );
        }

        setGlobalError(newGlobalError);
        setLoading(false);
        return;
      }

      // Short timeout gives the user a feeling of something happening
      setTimeout(() => {
        setStep(STEP_SUCCESS);

        setLoading(false);
      }, 500);
    },
    [fieldError, password, token]
  );

  if (AuthService.isAuthenticated()) {
    return <Redirect to={getDefaultAuthedRoute()} />;
  }

  return (
    <Page className={style.Auth} day={1}>
      <LeftPanel
        title={
          <Fragment>
            <strong>{DAY_ONE_CONTENT.title}</strong> <br />
            {DAY_ONE_CONTENT.sub_title}
          </Fragment>
        }
        description={
          <Fragment>
            {DAY_ONE_CONTENT.description.map((copy, index) => (
              <p key={index}>{copy}</p>
            ))}
          </Fragment>
        }
        quote={DAY_ONE_CONTENT.quote}
      />
      <RightPanel single>
        {step === STEP_PASSWORD && (
          <form onSubmit={handleSubmit} key={STEP_PASSWORD} noValidate>
            <Input
              id="password"
              fieldName="Password"
              label="Set a new password…"
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={fieldError}
              onError={handleError}
              placeholder="Enter a new password…"
              required
              min={8}
              autoFocus
              autoComplete="new-password"
              readonly={loading}
            />

            {password && (
              <ButtonWithPrompt
                amendPrompt={false}
                label="Set new password"
                loading={loading}
                disabled={fieldError}
              />
            )}
            {globalError && (
              <ErrorMessage
                className={style.AuthFormError}
                error={<strong>{globalError}</strong>}
              />
            )}
          </form>
        )}
        {step === STEP_SUCCESS && (
          <div className={style.AuthResetSuccess}>
            <p>Your password was successfully reset.</p>
            <Button to={ROUTE_AUTH}>Log in</Button>
          </div>
        )}
      </RightPanel>
    </Page>
  );
};

export default Auth;
