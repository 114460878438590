import React, { forwardRef, useMemo } from "react";
import { generatePath, useHistory } from "react-router";
import cc from "classcat";

import { days } from "../../days.json";
import { ROUTE_DAY_QUESTION } from "../../App";

import { ReactComponent as CheckIcon } from "../../images/icons/check.svg";
import { ReactComponent as DotIcon } from "../../images/icons/dot.svg";
import { ReactComponent as SharePreviewIcon } from "../../images/icons/share-preview.svg";

import style from "./Menu.module.scss";

export const Menu = forwardRef(({ className, children }, ref) => {
  return (
    <ul ref={ref} className={cc([style.Menu, className])}>
      {children}
    </ul>
  );
});

export const MenuItem = ({
  className,
  disabled,
  icon,
  onClick,
  children,
  ...rest
}) => {
  const containerClassName = cc([
    style.Container,
    className,
    {
      [style.MenuItemButton]: !!onClick,
      [style.MenuItemWithIcon]: !!icon,
      [style.MenuItemDisabled]: disabled,
    },
  ]);
  const content = icon ? (
    <>
      {icon}
      <div className={style.ContainerWithIcon}>{children}</div>
    </>
  ) : (
    children
  );
  return (
    <li className={cc([style.MenuItem])}>
      {onClick && !disabled ? (
        <button onClick={onClick} className={containerClassName} {...rest}>
          {content}
        </button>
      ) : (
        <div className={containerClassName} {...rest}>
          {content}
        </div>
      )}
    </li>
  );
};

export const MenuItemComment = ({
  fieldId,
  text,
  author,
  read,
  onClick,
  onMarkAsRead,
}) => {
  const history = useHistory();

  const { day, questionNumber, route } = useMemo(() => {
    let dayIndex, questionIndex;

    daysLoop: for (let i = 0; i < days.length; i++) {
      if (!days[i].questions) continue;
      for (let j = 0; j < days[i].questions.length; j++) {
        if (days[i].questions[j].fields.find(({ id }) => id === fieldId)) {
          dayIndex = i;
          questionIndex = j;
          break daysLoop;
        }
      }
    }

    if (dayIndex === undefined) {
      console.error(`Unexpected fieldId: ${fieldId}`);
      dayIndex = 1;
      questionIndex = 0;
    }

    return {
      day: dayIndex + 1,
      questionNumber: questionIndex + 1,
      route: generatePath(ROUTE_DAY_QUESTION, {
        dayId: dayIndex + 1,
        questionId: days[dayIndex].questions[questionIndex].id,
      }),
    };
  }, [fieldId]);

  const handleClick = () => {
    onClick?.();
    if (!read) onMarkAsRead?.();
    history.push(route);
  };

  const handleMarkAsReadClick = (e) => {
    // Prevent the click handler on the parent from firing
    e.stopPropagation();
    onMarkAsRead?.();
  };

  return (
    <MenuItem>
      <article className={style.Comment} onClick={handleClick}>
        <div className={style.ReadStatus}>
          {read ? (
            <CheckIcon role="presentation" />
          ) : (
            <DotIcon role="presentation" />
          )}
        </div>
        <header>
          <h4>
            Day {day} / Q{questionNumber}
          </h4>
          {!read && (
            <button onClick={handleMarkAsReadClick}>Mark as read</button>
          )}
        </header>
        <p>{text}</p>
        <address>{author}</address>
      </article>
    </MenuItem>
  );
};

export const MenuItemShareButton = ({ disabled, onClick, label }) => {
  return (
    <MenuItem
      disabled={disabled}
      onClick={onClick}
      icon={<SharePreviewIcon role="presentation" />}
    >
      {label}
    </MenuItem>
  );
};
