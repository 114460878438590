import React, { useCallback, useMemo, useRef, useState } from "react";
import cc from "classcat";
import { useHistory, generatePath } from "react-router-dom";

import {
  ROUTE_NEW_VALUES_SHARED,
  ROUTE_POSTER_SHARED,
  ROUTE_PRESENT_VALUES_SHARED,
} from "../../App";

import AuthService from "../../services/auth.service";
import SharingService, {
  SHARED_CHECKPOINT_OPTIONS,
  SHARING_CHECKPOINT_DAY_IDS,
  SHARED_PROPERTY_NAMES,
} from "../../services/sharing.service";

import useOnClickOutside from "../../hooks/useOnClickOutside";
import { useAppContext } from "../../contexts/AppContext";
import { useCommentsContext } from "../../contexts/CommentsContext";
import { POSTER_STYLE } from "../Poster/poster.config";

import { Menu, MenuItemShareButton, MenuItemComment } from "./Menu";

import { ReactComponent as CommentsIcon } from "../../images/icons/comments.svg";
import { ReactComponent as LogoutIcon } from "../../images/icons/logout.svg";
import { ReactComponent as ShareIcon } from "../../images/icons/share.svg";

import style from "./index.module.scss";

const SESSION_THEME_SELECTION = "selectedTheme";

const Toolbar = () => {
  const { logout } = useAppContext();
  const { comments, unreadComments, markAsRead } = useCommentsContext();

  const commentsButtonRef = useRef();
  const commentsRef = useRef();
  const [commentsOpened, setCommentsOpened] = useState(false);

  useOnClickOutside(
    () => setCommentsOpened(false),
    commentsRef,
    commentsButtonRef
  );

  const { answer } = AuthService.getUser();

  const history = useHistory();

  const isPresentValuesReady = useMemo(() => {
    const oldTowards = answer?.[SHARED_PROPERTY_NAMES.OLD_TOWARDS];
    const oldAway = answer?.[SHARED_PROPERTY_NAMES.OLD_AWAY];
    return (
      Array.isArray(oldTowards) &&
      oldTowards.length > 0 &&
      Array.isArray(oldAway) &&
      oldAway.length > 0
    );
  }, [answer]);

  const isNewValuesReady = useMemo(() => {
    const newTowards = answer?.[SHARED_PROPERTY_NAMES.NEW_TOWARDS];
    const newAway = answer?.[SHARED_PROPERTY_NAMES.NEW_AWAY];
    return (
      isPresentValuesReady &&
      Array.isArray(newTowards) &&
      newTowards.length > 0 &&
      Array.isArray(newAway) &&
      newAway.length > 0
    );
  }, [answer, isPresentValuesReady]);

  const isDestinyReady = useMemo(
    () =>
      isPresentValuesReady &&
      isNewValuesReady &&
      !!answer?.[SHARED_PROPERTY_NAMES.MISSION_STATEMENT],
    [answer, isPresentValuesReady, isNewValuesReady]
  );

  const shareButtonRef = useRef();
  const shareDialogRef = useRef();
  const [isShareDropdownOpened, setIsShareDropdownOpened] = useState(false);

  useOnClickOutside(
    () => setIsShareDropdownOpened(false),
    shareDialogRef,
    shareButtonRef
  );

  const selectedPosterStyle =
    sessionStorage.getItem(SESSION_THEME_SELECTION) || POSTER_STYLE.BLUE;

  const shareProgress = useCallback(
    (checkpoint) => {
      const share = async () => {
        const { hash } = await SharingService.shareProgress(checkpoint);
        if (checkpoint === SHARED_CHECKPOINT_OPTIONS.DESTINY) {
          history.push(
            generatePath(ROUTE_POSTER_SHARED, {
              hash,
              style: selectedPosterStyle,
            })
          );
        } else {
          const checkpointToRoute = {
            [SHARED_CHECKPOINT_OPTIONS.THIRD_DAY]: ROUTE_PRESENT_VALUES_SHARED,
            [SHARED_CHECKPOINT_OPTIONS.FIFTH_DAY]: ROUTE_NEW_VALUES_SHARED,
          };
          const sharedRoute = checkpointToRoute[checkpoint];
          if (!sharedRoute) {
            throw new Error(`Unknown checkpoint ${checkpoint}`);
          }
          history.push(generatePath(sharedRoute, { hash }));
        }
      };
      share();
    },
    [history, selectedPosterStyle]
  );

  const shareMenuOptions = useMemo(
    () => [
      {
        id: SHARING_CHECKPOINT_DAY_IDS.PRESENT_VALUES,
        label: "Share Present Values",
        onClick: () => shareProgress(SHARED_CHECKPOINT_OPTIONS.THIRD_DAY),
        disabled: !isPresentValuesReady,
      },
      {
        id: SHARING_CHECKPOINT_DAY_IDS.NEW_VALUES,
        label: "Share Present and New Values",
        onClick: () => shareProgress(SHARED_CHECKPOINT_OPTIONS.FIFTH_DAY),
        disabled: !isNewValuesReady,
      },
      {
        id: SHARING_CHECKPOINT_DAY_IDS.DESTINY,
        label: "Share Destiny Poster",
        onClick: () => shareProgress(SHARED_CHECKPOINT_OPTIONS.DESTINY),
        disabled: !isDestinyReady,
      },
    ],
    [isDestinyReady, isPresentValuesReady, isNewValuesReady, shareProgress]
  );

  return (
    <div className={style.Toolbar}>
      {isPresentValuesReady && (
        <button
          ref={shareButtonRef}
          className={cc([isShareDropdownOpened && style.Active])}
          onClick={() => setIsShareDropdownOpened(!isShareDropdownOpened)}
        >
          <div>
            <ShareIcon role="presentation" />
          </div>
          Share
        </button>
      )}
      {comments.length > 0 && (
        <button
          ref={commentsButtonRef}
          className={cc([
            commentsOpened && style.Active,
            unreadComments && style.HasUnreadComments,
          ])}
          onClick={() => setCommentsOpened(!commentsOpened)}
        >
          <div>
            <CommentsIcon role="presentation" />
          </div>
          Comments
        </button>
      )}
      <button className={style.Logout} onClick={logout}>
        <div>
          <LogoutIcon role="presentation" />
        </div>
        Logout
      </button>
      {commentsOpened && (
        <Menu ref={commentsRef}>
          {comments.map(({ id, fieldId, text, author, read }) => (
            <MenuItemComment
              key={id}
              fieldId={fieldId}
              text={text}
              author={author}
              read={read}
              onMarkAsRead={() => markAsRead(id)}
              onClick={() => setCommentsOpened(false)}
            />
          ))}
        </Menu>
      )}
      {isShareDropdownOpened && (
        <Menu ref={shareDialogRef}>
          {shareMenuOptions.map((props) => (
            <MenuItemShareButton key={props.id} {...props} />
          ))}
        </Menu>
      )}
    </div>
  );
};

export default Toolbar;
