import api from "./api.service";

const FETCH_CLIENTS_PAGE_SIZE = 10000;

let clientsCache;
let clientsListFetched;

export async function resetClientsCache() {
  clientsCache = [];
  clientsListFetched = false;
}

resetClientsCache();

export async function getClients(flushCache = false) {
  if (clientsListFetched === false || flushCache) {
    const newClients = {};
    let next = `/groups/members?per_page=${FETCH_CLIENTS_PAGE_SIZE}`;
    do {
      const response = await api().get(next);
      for (const attendee of response.data.results) {
        newClients[attendee.id] = attendee;
      }
      next = response.data.next;
    } while (next !== null);
    clientsCache = newClients;
    clientsListFetched = true;
  }

  return clientsCache;
}

export async function getClient(id, fromCache = false) {
  if (fromCache) {
    return clientsCache[id];
  }
  const response = await api().get(`/groups/members?user_id=${id}`);
  const client = response.data.results[0];
  clientsCache[id] = client;
  return client;
}

export async function flagClient(id, flagged) {
  const flagApiUrl = flagged
    ? `/users/${id}/mark-at-risk`
    : `/users/${id}/unmark-at-risk`;
  await api().post(flagApiUrl);
  // Create brand new objects so React can detect a change in references
  clientsCache = {
    ...clientsCache,
    [id]: {
      ...clientsCache[id],
      is_client_at_risk: flagged,
    },
  };
}

export async function updateClientNotes(id, notes) {
  await api().post(`/users/${id}/trainer-notes`, {
    trainer_private_notes: notes,
  });
  clientsCache = {
    ...clientsCache,
    [id]: {
      ...clientsCache[id],
      trainer_private_notes: notes,
    },
  };
}
