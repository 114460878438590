import React, {
  forwardRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
  Fragment,
} from "react";
import cc from "classcat";
import { isEmail } from "validator";

import useUniqueId from "../../hooks/useUniqueId";

import ErrorMessage from "../ErrorMessage";

import { ReactComponent as EyeOpenIcon } from "../../images/icons/eye-open.svg";
import { ReactComponent as EyeClosedIcon } from "../../images/icons/eye-closed.svg";

import style from "./index.module.scss";

const INPUT_BORDER_WIDTH = +style.inputBottomBorderWidth;
export const INPUT_TEXTAREA_LONG_MAX = 150;

const Input = forwardRef(
  (
    {
      id: _id,
      className,
      fieldName = "Field",
      label,
      defaultValue,
      value,
      onChange,
      onBlur,
      error,
      onError,
      required,
      helper: _helper,
      number,
      min,
      max,
      type = "text",
      readonly,
      ...props
    },
    ref
  ) => {
    const [touched, setTouched] = useState(false);
    const [implicitType, setImplicitType] = useState(type);

    const hasError = touched && error;
    const { InputTag, textareaType } = useMemo(() => {
      let InputTag = "input";
      let textareaType = null;

      if (type === "text" && max > 32) {
        InputTag = "textarea";
        textareaType = max > INPUT_TEXTAREA_LONG_MAX ? "long" : "short";
      }

      return {
        InputTag,
        textareaType,
      };
    }, [type, max]);

    const uniqueId = useUniqueId();
    const id = _id || uniqueId;

    useEffect(() => {
      if (!ref) return;

      if (InputTag === "textarea") {
        // Resizes the textarea to allow it to only take up the space it needs
        ref.current.style.height = "";
        ref.current.style.height = `${
          ref.current.scrollHeight + INPUT_BORDER_WIDTH
        }px`;
      }
    }, [ref, InputTag, value]);

    const handlePasswordToggle = useCallback((e) => {
      e.preventDefault();
      setImplicitType((type) => (type === "password" ? "text" : "password"));
    }, []);

    const checkError = useCallback(
      (newValue) => {
        if (typeof onError !== "function") return;

        let newError = null;

        if (required && (newValue || "").trim() === "") {
          newError = `${fieldName} cannot be empty`;
        } else if (type === "email" && !isEmail(newValue)) {
          newError = "Invalid email address";
        } else if (min && newValue.length < min) {
          newError = `${fieldName} must be ${min} characters or more`;
        } else if (max && newValue.length > max) {
          newError = `${fieldName} must be ${max} characters or fewer`;
        }

        onError(newError, id);
      },
      [id, fieldName, type, required, max, min, onError]
    );

    useEffect(() => {
      checkError(value);
    }, [value, checkError]);

    const handleBlur = useCallback(
      (e) => {
        setTouched(true);

        if (typeof onBlur === "function") {
          onBlur(e);
        }
      },
      [onBlur]
    );

    const handleChange = useCallback(
      (e) => {
        if (typeof onChange !== "function") return;

        let sanitizedValue = e.target.value;

        if (textareaType === "short") {
          // Replaces carriage returns and line breaks with a space to prevent
          // anything other than a single line from being created for short
          // textareas
          sanitizedValue = sanitizedValue.replace(/[\n\r]/g, "");
        } else {
          // Prevents users from typing two line breaks in a row which stops
          // them creating stupidly long textareas that scroll with no purpose
          sanitizedValue = sanitizedValue
            .replace(/\n+/g, "\n")
            .replace(/\r+/g, "\r");
        }

        // In Safari the cursor is moved to the end of the text field every
        // time you set e.target.value, even if the string you set is identical
        // to the one already there. To avoid the cursor jumping to the end for
        // no reason we touch e.target.value only if necessary
        if (sanitizedValue !== e.target.value) {
          e.target.value = sanitizedValue;
        }

        onChange(e);
      },
      [onChange, textareaType]
    );

    const errorId = `${id}-error`;

    const helper = useMemo(() => {
      // Allows for helper={false} for places where it should be hidden
      if (_helper) return _helper;

      if (_helper === false || (!min && !max)) return null;

      const helperString =
        min && max
          ? `Between ${min}–${max}`
          : `${min ? "Min" : "Max"} ${min || max}`;

      return (
        <Fragment>
          {helperString} <abbr title="characters">chars</abbr>
        </Fragment>
      );
    }, [_helper, min, max]);

    return (
      <label
        className={cc([
          className,
          style.Input,
          {
            [style.InputReadonly]: readonly,
            [style.InputWide]: type === "textarea",
            [style.InputHasNumber]: number,
            [style.InputTextareaShort]: textareaType === "short",
            [style.InputTextareaLong]: textareaType === "long",
          },
        ])}
      >
        <span className={style.InputLabel}>
          {label && (
            <span
              className={style.InputLabelText}
              aria-hidden={props["aria-label"]}
            >
              {label}
            </span>
          )}
          {helper && <span className={style.InputLabelHelper}>{helper}</span>}
        </span>
        {number && <span className={style.InputNumber}>{number}</span>}
        <div className={style.InputWrapperOuter}>
          <div className={style.InputWrapperInner}>
            <InputTag
              ref={ref}
              className={cc([
                style.InputField,
                {
                  [style.InputFieldPassword]: type === "password",
                  [style.InputFieldInvalid]: hasError,
                  [style.InputFieldTextareaShort]: textareaType === "short",
                  [style.InputFieldTextareaLong]: textareaType === "long",
                },
              ])}
              id={id}
              onChange={handleChange}
              onBlur={handleBlur}
              value={value}
              type={implicitType}
              {...props}
              aria-describedby={hasError && errorId}
              readOnly={readonly}
              rows={InputTag === "textarea" ? 1 : undefined}
            />
            {type === "password" && (
              <button
                className={style.InputPasswordToggle}
                onClick={handlePasswordToggle}
                aria-label={
                  implicitType === "password"
                    ? "Hide Password"
                    : "Show Password"
                }
                type="button"
              >
                {implicitType === "password" ? (
                  <EyeOpenIcon role="presentation" />
                ) : (
                  <EyeClosedIcon role="presentation" />
                )}
              </button>
            )}
            {InputTag === "textarea" && value && (
              <span
                className={cc([
                  style.InputCounter,
                  {
                    [style.InputCounterError]:
                      hasError &&
                      ((max && value.length > max) ||
                        (min && value.length < min)),
                  },
                ])}
                aria-hidden="true"
              >
                {`${value.length}`.split("").map((num, index) => (
                  // Little hack to make a reverse index for keying
                  <span key={20 - index}>{num}</span>
                ))}
              </span>
            )}
          </div>
        </div>
        {hasError && (
          <ErrorMessage
            className={style.InputError}
            id={errorId}
            error={error}
          />
        )}
      </label>
    );
  }
);

Input.displayName = "Input";

export default Input;
