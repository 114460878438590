import React from "react";
import cc from "classcat";
import { ReactComponent as RefreshIcon } from "../../images/icons/refresh.svg";
import style from "./index.module.scss";

const AdminRefreshButton = ({ className, loading = false, ...props }) => (
  <button
    className={cc([style.RefreshButton, className])}
    disabled={loading}
    {...props}
  >
    {loading ? <div className={style.Spinner} /> : <RefreshIcon />}
  </button>
);

export default AdminRefreshButton;
